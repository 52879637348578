import { Status } from '@/@types'
import { ReactEditor } from '@/components/organisms/Editor/Editor'
import { ClusterConfigContext } from '@/stores/clusterConfig'
import { EditorStatus, SelfConfigContext } from '@/stores/selfconfig'
import { Box, Button, Flex, Input, Text } from '@chakra-ui/react'
import { colors } from '@hivemq/ui-theme'
import { createRoute } from '@tanstack/react-router'
import * as React from 'react'
import YAML from 'yaml'
import { clustersRootRoute } from '../../router/rootRoutes'

export const newClusterConfigRoute = createRoute({
  getParentRoute: () => clustersRootRoute,
  path: 'new',
  component: () => <NewClusterConfig />,
})

export function NewClusterConfig() {
  const { getTheme } = React.useContext(SelfConfigContext)
  const {
    createNewConfig,
    resetCreateStatus,
    state: clusterState,
  } = React.useContext(ClusterConfigContext)

  const [state, setState] = React.useState({
    config: '',
    orgId: '',
    errors: '',
  })

  React.useEffect(() => {
    if (clusterState.createStatus === Status.Error) {
      setState((state) => ({
        ...state,
        errors:
          'Error createing cluster! Please confirm that all required data are provided',
      }))
    } else {
      setState((state) => ({ ...state, errors: '' }))
    }
  }, [clusterState.createStatus])

  React.useEffect(() => {
    resetCreateStatus()
  }, [])

  function onChangeConfig(config?: string) {
    if (config) {
      setState((state) => ({ ...state, errors: '', config }))
    }
  }
  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target
    setState((state) => ({ ...state, [name]: value }))
  }

  function onSubmit() {
    try {
      const data = YAML.parse(state.config)
      createNewConfig(data, state.orgId)
    } catch (error) {
      setState((state) => ({
        ...state,
        errors: "Please ensure you're providing a valid yaml config",
      }))
    }
  }

  const disableSubmit =
    !!state.errors.length ||
    !state.config.length ||
    clusterState.createStatus === Status.Loading ||
    !state.orgId

  return (
    <Box as="article" w={'85%'} mt={'6'}>
      <Text mb={'14'} fontSize={'3xl'} fontWeight={'600'}>
        Create New Cluster Config
      </Text>

      <Box>
        <Box mb={'14'}>
          <Text mb={'2'} fontWeight={'bold'}>
            Organization Id:{' '}
          </Text>
          <Input
            name="orgId"
            width={'20rem'}
            value={state.orgId}
            onChange={onChange}
          />
        </Box>

        <Box>
          <Text mb={'2'} fontWeight={'bold'}>
            Cluster config:
          </Text>
          <ReactEditor
            onChange={onChangeConfig}
            height={'35rem'}
            options={getTheme(EditorStatus.ReadAndWrite)}
            value={state.config}
            language="yaml"
          />
          <Text mt={'2'} color={colors.red[300]}>
            {state.errors}
          </Text>
        </Box>
      </Box>

      <Flex justifyContent={'end'} mt={'6'}>
        <Button
          onClick={onSubmit}
          color={colors.neutrals.WHITE}
          backgroundColor={'#068844'}
          borderColor={colors.neutrals[200]}
          paddingY={'0'}
          paddingX={'4'}
          isDisabled={disableSubmit}
          _disabled={{
            pointerEvents: 'none',
            opacity: '0.6',
            cursor: 'not-allowed',
          }}
        >
          {clusterState.createStatus === Status.Loading
            ? 'Submitting'
            : 'Submit'}
        </Button>
      </Flex>
    </Box>
  )
}
