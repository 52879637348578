import { Status } from '@/@types'
import { UserIdentity } from '@/@types/identity'
import { HTTPMethod, useFetchCall } from '@/helpers/useFetchCall'
import * as React from 'react'

type StoreState = {
  status: Status
  email: string
  identities: Array<UserIdentity>
  log: Array<{ action: IdentityAction; userId: string; status: Status }>
}

export enum IdentityAction {
  Activate,
  ResendVerification,
}

export const ACTIVATED = 'ACTIVATED'

type Identity = {
  storeState: StoreState
  activateUser: (_user: string, _signal?: AbortSignal) => void
  getUserByEmail: (_email: string, _signal?: AbortSignal) => void
  resendVerification: (_user: string, _signal?: AbortSignal) => void
}
export const IdentitySupportContext = React.createContext<Identity>(
  {} as Identity
)

export function IdentitySupportProvider({ children }: React.PropsWithChildren) {
  const { fetchCall } = useFetchCall()

  const [storeState, setStoreState] = React.useState<StoreState>({
    status: Status.Rest,
    email: '',
    identities: [],
    log: [],
  })

  async function getUserByEmail(email: string, signal?: AbortSignal) {
    try {
      setStoreState({
        status: Status.Loading,
        identities: [],
        email,
        log: [],
      })

      const identities = (await fetchCall({
        path: `users`,
        method: HTTPMethod.GET,
        signal,
        params: { email },
      })) as unknown as Array<UserIdentity>

      setStoreState((state) => ({
        ...state,
        status: Status.Success,
        identities,
      }))
    } catch (error) {
      setStoreState((state) => ({ ...state, status: Status.Error }))
    }
  }

  async function activateUser(userId: string, signal?: AbortSignal) {
    try {
      setStoreState((state) => ({
        ...state,
        log: [
          ...state.log,
          {
            action: IdentityAction.Activate,
            userId,
            status: Status.Loading,
          },
        ],
      }))
      await fetchCall({
        path: 'users/activate',
        method: HTTPMethod.POST,
        signal,
        payload: { userId },
      })

      setStoreState((state) => ({
        ...state,
        log: [
          ...state.log,
          {
            action: IdentityAction.Activate,
            userId,
            status: Status.Success,
          },
        ],
      }))
    } catch (error) {
      setStoreState((state) => ({
        ...state,
        log: [
          ...state.log,
          {
            action: IdentityAction.Activate,
            userId,
            status: Status.Error,
          },
        ],
      }))
    }
  }

  async function resendVerification(userId: string, signal?: AbortSignal) {
    try {
      setStoreState((state) => ({
        ...state,
        log: [
          ...state.log,
          {
            action: IdentityAction.ResendVerification,
            userId,
            status: Status.Loading,
          },
        ],
      }))

      await fetchCall({
        path: 'users/resendActivationEmail',
        method: HTTPMethod.POST,
        signal,
        payload: { userId },
      })
      setStoreState((state) => ({
        ...state,
        log: [
          ...state.log,
          {
            action: IdentityAction.ResendVerification,
            userId,
            status: Status.Success,
          },
        ],
      }))
    } catch (error) {
      setStoreState((state) => ({
        ...state,
        log: [
          ...state.log,
          {
            action: IdentityAction.ResendVerification,
            userId,
            status: Status.Error,
          },
        ],
      }))
    }
  }

  const value = React.useMemo(
    () => ({
      getUserByEmail,
      activateUser,
      resendVerification,
      storeState,
    }),
    [storeState]
  )
  return (
    <IdentitySupportContext.Provider value={value}>
      {children}
    </IdentitySupportContext.Provider>
  )
}
