import { Status } from '@/@types'
import { type ClusterConfigState } from '@/@types/clusterConfig'
import type {
  ClusterCreationRevisionResponse,
  ClusterHead,
  ClustersRevisionsResponse,
} from '@/generated/http-clients/v3ApiSchemas'
import {
  ApiVersion,
  ErrorMessage,
  HTTPMethod,
  useFetchCall,
} from '@/helpers/useFetchCall'
import type { EditorProps } from '@monaco-editor/react'
import { useNavigate, useParams } from '@tanstack/react-router'
import * as React from 'react'
import YAML from 'yaml'
import { DisplayView, SelfConfigContext } from '../selfconfig'

type ClusterConfigs = {
  state: ClusterConfigState
  clusterConfigFiles: Array<Record<string, any>>
  getConfig: (_orgId: string, _clusterId: string, _?: AbortSignal) => void
  onChangeCode: EditorProps['onChange']
  createConfigRevision: (
    _orgId: string,
    _clusterId: string,
    comment?: string
  ) => void
  resetDraft: () => void
  resetUpdateStatus: () => void
  createNewConfig: (_: Record<string, any>, _orgId: string) => Promise<void>
  resetCreateStatus: () => void
}

const defaultOptions: ClusterConfigState = {
  update: Status.Rest,
  specificCluster: {
    data: null,
    modifiedData: null,
    status: Status.Rest,
  },
  clusters: {
    data: null,
    status: Status.Rest,
  },
  createStatus: Status.Rest,
}

export const ClusterConfigContext = React.createContext<ClusterConfigs>({
  state: defaultOptions,
} as ClusterConfigs)

export function ClusterConfigProvider({ children }: React.PropsWithChildren) {
  const { fetchCall } = useFetchCall()
  const { state: selfConfig, isDraftPage } = React.useContext(SelfConfigContext)

  // todo: make this more robust
  const params = useParams({ strict: false })
  const nav = useNavigate()

  React.useEffect(() => {
    if (params.orgId && params.clusterId) {
      getConfig(params.orgId, params.clusterId)
    }
  }, [params?.orgId, params?.clusterId])

  React.useEffect(() => {
    getAllConfigs()
  }, [])

  const [state, setState] =
    React.useState<ClusterConfigs['state']>(defaultOptions)

  React.useEffect(() => {
    // anytime the clusterId changes, meaning that the user has left this cluster, we reset the `draft` modifiedData
    cleanupDraft()
    if (params.clusterId) {
      // we repopulate when a new clusterId is made
      resetDraft()
    }
  }, [params?.clusterId, state.specificCluster.data])

  function cleanupDraft() {
    setState((state) => ({
      ...state,
      specificCluster: { ...state.specificCluster, modifiedData: null },
    }))
  }

  function resetDraft() {
    setState((state) => ({
      ...state,
      specificCluster: {
        ...state.specificCluster,
        modifiedData: state.specificCluster.data,
      },
    }))
  }

  async function getConfig(
    orgId: string,
    clusterId: string,
    signal?: AbortSignal
  ) {
    try {
      setState((state) => ({
        ...state,
        specificCluster: {
          status: Status.Loading,
          data: null,
          modifiedData: null,
        },
      }))

      const data = await fetchCall({
        path: `orgs/${orgId}/clusters/${clusterId}`,
        method: HTTPMethod.GET,
        signal,
        version: ApiVersion.V3,
      })

      setState((state) => ({
        ...state,
        specificCluster: {
          status: Status.Success,
          modifiedData: data,
          data,
        },
      }))
    } catch (error) {
      const status =
        (error as Error).message === ErrorMessage.NotFound
          ? Status.Success
          : Status.Error
      setState((state) => ({
        ...state,
        specificCluster: { ...state.specificCluster, status },
      }))
    }
  }

  async function createConfigRevision(
    orgId: string,
    clusterId: string,
    comment?: string
  ) {
    try {
      setState((state) => ({
        ...state,
        update: Status.Loading,
      }))
      await fetchCall<ClusterHead>({
        path: `orgs/${orgId}/clusters/${clusterId}`,
        method: HTTPMethod.PUT,
        version: ApiVersion.V3,
        payload: { ...state.specificCluster.modifiedData, changeLog: comment },
      })

      setState((state) => ({
        ...state,
        update: Status.Success,
        specificCluster: {
          ...state.specificCluster,
          modifiedData: state.specificCluster.data,
        },
      }))

      // todo: update to real path
      nav({
        to: `clusters/${orgId}/${clusterId}/revisions`,
      })
    } catch (error) {
      setState((state) => ({
        ...state,
        update: Status.Error,
      }))
    }
  }

  const clusterConfigFiles = React.useMemo(() => {
    const stateData = isDraftPage
      ? state.specificCluster.modifiedData
      : state.specificCluster.data
    if (stateData) {
      const data = Object.entries(stateData).reduce((prev, [key, value]) => {
        if (['template_id', 'customer', 'system', 'override'].includes(key)) {
          if (selfConfig.display === DisplayView.Layers) {
            prev.push({ [key]: YAML.stringify({ [key]: value }) })
          } else if (!prev.length) {
            prev = [{ [key]: YAML.stringify(stateData) }]
          }
        }
        return prev
      }, [] as Array<Record<string, string>>)

      return data
    }

    return []
  }, [state, selfConfig?.display, isDraftPage])

  function onChangeCode(value?: string) {
    if (value) {
      const data = YAML.parse(value)
      setState((state) => ({
        ...state,
        specificCluster: {
          ...state.specificCluster,
          modifiedData: { ...state.specificCluster.modifiedData, ...data },
        },
      }))
    }
  }

  async function getAllConfigs() {
    try {
      setState((state) => ({
        ...state,
        clusters: { data: null, status: Status.Loading },
      }))
      const data = await fetchCall<ClustersRevisionsResponse>({
        path: `clusters`,
        version: ApiVersion.V3,
        method: HTTPMethod.GET,
      })

      setState((state) => ({
        ...state,
        clusters: { data: data?.revisions || [], status: Status.Success },
      }))
    } catch (error) {
      setState((state) => ({
        ...state,
        clusters: { status: Status.Error, data: null },
      }))
    }
  }

  async function createNewConfig(payload: Record<string, any>, orgId: string) {
    try {
      setState((state) => ({ ...state, createStatus: Status.Loading }))
      const data = (await fetchCall<ClusterCreationRevisionResponse>({
        path: `orgs/${orgId}/clusters`,
        method: HTTPMethod.POST,
        version: ApiVersion.V3,
        payload,
      }))!
      setTimeout(() => {
        setState((state) => ({ ...state, createStatus: Status.Success }))

        // todo: update to real path
        nav({
          to: `clusters/${orgId}/${data.hive_id}/revisions`,
        })
      }, 500)
    } catch (error) {
      setState((state) => ({ ...state, createStatus: Status.Error }))
    }
  }

  function resetUpdateStatus() {
    setState((state) => ({ ...state, update: Status.Success }))
  }

  function resetCreateStatus() {
    setState((state) => ({ ...state, createStatus: Status.Rest }))
  }

  const value = React.useMemo(
    () => ({
      state,
      getConfig,
      clusterConfigFiles,
      onChangeCode,
      createConfigRevision,
      resetDraft,
      resetUpdateStatus,
      createNewConfig,
      resetCreateStatus,
    }),
    [state, selfConfig?.display, isDraftPage]
  )

  return (
    <ClusterConfigContext.Provider value={value}>
      {children}
    </ClusterConfigContext.Provider>
  )
}
