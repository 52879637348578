import { createRouter } from '@tanstack/react-router'
import { organizationDetailsRoute } from '@/features/Organisations/components/OrganizationDetails'
import { organizationRoute } from '@/features/Organisations/page/OrganizationTable'
import { templateTableRoute } from '@/features/Templates/page/TemplateTable'
import { clusterConfigDiffRoute } from '@/Pages/ClusterConfigDiff/ClusterConfigDiff'
import { clusterConfigDraftRoute } from '@/Pages/ClusterConfigDraft/ClusterConfigDraft'
import { clusterConfigHeadRoute } from '@/Pages/ClusterConfigHead/ClusterConfigHead'
import { clusterConfigRevisionsRoute } from '@/Pages/ClusterConfigRevisions/ClusterConfigRevisions'
import { clustersRoute } from '@/Pages/ClusterConfigs/ClusterConfigs'
import { clusterConfigsTabRoute } from '@/Pages/ClusterConfigsTab/ClusterConfigsTab'
import { dynamicClusterCompareRoute } from '@/Pages/DynamicClusterCompare/DynamicClusterCompare'
import { dynamicTemplateCompareRoute } from '@/Pages/DynamicTemplateCompare/DynamicTemplateCompare'
import { identitySupportRoute } from '@/Pages/IdentitySupport/IdentitySupport'
import { newClusterConfigRoute } from '@/Pages/NewClusterConfig/NewClusterConfig'
import { loginCallbackRoute, loginRoute } from './authentication/login'
import {
  applicationRootRoute,
  clustersRootRoute,
  organizationRootRoute,
  rootRoute,
  templatesRootRoute,
} from './rootRoutes'
import { templateCreateRoute } from '@/features/Templates/components/TemplateCreate'
import { templateDetailsRoute } from '@/features/Templates/components/TemplateDetails'

const routeTree = rootRoute.addChildren([
  /**
   * Main application routes that define the inner contents of the application after a successful login
   */
  applicationRootRoute.addChildren([
    /**
     * All organization routes that are currently set as the index of the application
     */
    organizationRootRoute.addChildren([
      organizationRoute,
      organizationDetailsRoute,
    ]),

    /**
     * All cluster routes nested withing `/clusters` as of now
     */
    clustersRootRoute.addChildren([
      clustersRoute,
      newClusterConfigRoute,

      clusterConfigsTabRoute.addChildren([
        clusterConfigHeadRoute,
        clusterConfigDraftRoute,
        clusterConfigRevisionsRoute,
        dynamicClusterCompareRoute,
        clusterConfigDiffRoute,
      ]),
    ]),

    /**
     * All functionality related to templates nested withing `/templates` as of now
     */
    templatesRootRoute.addChildren([
      templateTableRoute,
      templateCreateRoute,
      templateDetailsRoute,
      dynamicTemplateCompareRoute,

      // templateConfigRoute.addChildren([templateHeadRoute, templateDraftRoute]),
    ]),

    /**
     * All functionality related to identity support nested withing `/identity` as of now
     */
    identitySupportRoute,
  ]),

  /**
   * !important: do not remove these routes
   * Authentication routes that are not part of the applicationRootRoute
   * and handle the whole authentication flow via okta
   */
  loginRoute,
  loginCallbackRoute,
])

export const router = createRouter({
  routeTree,
  context: {
    auth: undefined!, // This will be set after we wrap the app in an AuthProvider
  },
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}
