import { Status } from '@/@types'
import { ClusterCodeMgr } from '@/components/organisms/ClusterCodeMgr/ClusterCodeMgr'
import { ClusterConfigContext } from '@/stores/clusterConfig'
import { EditorStatus, SelfConfigContext } from '@/stores/selfconfig'
import { createRoute } from '@tanstack/react-router'
import { Loader } from 'lucide-react'
import * as React from 'react'
import { clusterConfigsTabRoute } from '../ClusterConfigsTab/ClusterConfigsTab'

export const clusterConfigHeadRoute = createRoute({
  getParentRoute: () => clusterConfigsTabRoute,
  path: '/',
  component: () => <ClusterConfigHead />,
})

function ClusterConfigHead() {
  const { getTheme } = React.useContext(SelfConfigContext)
  const { clusterConfigFiles, state } = React.useContext(ClusterConfigContext)

  return (
    <article>
      {state.specificCluster.status === Status.Success && (
        <ClusterCodeMgr
          customOptions={{
            ...getTheme(EditorStatus.ReadOnly),
            language: 'yaml',
          }}
          configFiles={clusterConfigFiles}
        />
      )}
      {state.specificCluster.status === Status.Loading && <Loader />}
      {state.specificCluster.status === Status.Success &&
        !state.specificCluster.data && (
          <p>No config applied to this cluster yet</p>
        )}
      {state.specificCluster.status === Status.Error && (
        <p>Error Loading Cluster Config</p>
      )}
    </article>
  )
}
