export type Environment = 'production' | 'staging' | 'development' | 'test'

export function getEnvironment(): Environment {
  let environment: Environment = 'development'

  if (process.env.NODE_ENV === 'production') {
    if (
      window.location.hostname ===
      'backoffice-frontend.console.portal.euc1.aws.hivemq.cloud'
    ) {
      environment = 'production'
    } else {
      environment = 'staging'
    }
  }

  if (
    process.env.NODE_ENV ===
    'backoffice-frontend.console.portal.stg.euc1.aws.hmqc.dev'
  ) {
    environment = 'test'
  }

  return environment
}

export const isProduction = getEnvironment() === 'production'
export const isStaging = getEnvironment() === 'staging'
export const isDevelop = getEnvironment() === 'development'
export const isTesting = getEnvironment() === 'test'
