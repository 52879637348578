import * as React from 'react'
import styles from './Input.module.css'
import classnames from 'classnames'

type DefaultInputAttributes = React.InputHTMLAttributes<HTMLInputElement>

type RequiredInputAttributes = Pick<
  Required<DefaultInputAttributes>,
  'onChange' | 'name' | 'value' | 'type'
>

type Props = DefaultInputAttributes &
  RequiredInputAttributes & {
    label?: string
    hint?: string
    classes?: {
      input?: string
      wrapper?: string
      label?: string
      hint?: string
      inputWrapper?: string
    }
  }

export function Input({
  type,
  name,
  value,
  onChange,
  label,
  classes,
  hint,
  ...props
}: Props) {
  return (
    <div className={classnames(styles.wrapper, classes?.wrapper)}>
      {label ? (
        <label
          htmlFor={name}
          className={classnames(styles.label, classes?.label)}
        >
          {label}
        </label>
      ) : null}

      <div className={classes?.inputWrapper}>
        <input
          className={classnames(styles.input, classes?.input)}
          onChange={onChange}
          type={type}
          name={name}
          value={value}
          {...props}
        />
        <p className={classnames(styles.hint, classes?.hint)}>{hint}</p>
      </div>
    </div>
  )
}
