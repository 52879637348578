import { Button } from '@/components/atoms/Button/Button'
import * as React from 'react'
import styles from './NavigationBar.module.css'
import { useOktaAuth } from '@okta/okta-react'

export function NavigationBar() {
  const { oktaAuth } = useOktaAuth()

  return (
    <nav className={styles.navBar}>
      <ol className={styles.navBarOl}>
        <li className={styles.navBarLi}>
          <Button
            type="button"
            classes={styles.navLogout}
            onClick={() =>
              oktaAuth.signOut({
                clearTokensBeforeRedirect: true,
                postLogoutRedirectUri: window.location.origin,
              })
            }
          >
            LogOut
          </Button>
        </li>
      </ol>
    </nav>
  )
}
