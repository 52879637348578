import * as monaco from 'monaco-editor'
import { Editor } from '@monaco-editor/react'
import React, { useState, useEffect, useRef } from 'react'

type TemplateEditorProps = {
  yamlContent?: string
  readOnly?: boolean
  onChange?: (value: string | undefined) => void // Callback for content changes
}

type MonacoEditorType = monaco.editor.IStandaloneCodeEditor

export function TemplateEditor({
  yamlContent = '',
  readOnly = false,
  onChange,
}: TemplateEditorProps) {
  const [content, setContent] = useState<string | undefined>(yamlContent)
  const [height, setHeight] = React.useState(600)
  const editorRef = useRef<MonacoEditorType | null>(null)

  // Define a custom light theme with YAML value styling
  useEffect(() => {
    monaco.editor.defineTheme('customLightYamlTheme', {
      base: 'hc-light',
      inherit: true,
      rules: [
        { token: 'string.yaml', foreground: '0000ff' },
        { token: 'key.yaml', foreground: '000000' },
      ],
      colors: {
        'editor.background': '#ffffff',
        'editor.foreground': '#000000',
        'editor.lineHighlightBackground': '#f4f4f4',
      },
    })
  }, [])

  function updateEditorHeight() {
    if (editorRef.current) {
      const lineCount = editorRef.current.getModel()?.getLineCount()
      // @RobinAtherton - FIXME This is still wrong
      const lineHeight = 25
      const newHeight = lineHeight * (1 + lineCount! + 20) // Adds padding
      editorRef.current.layout()
      setHeight(newHeight)
    }
  }

  React.useEffect(() => {
    updateEditorHeight()
  }, [content, yamlContent, monaco])

  const handleEditorChange = (value: string | undefined) => {
    if (!readOnly) {
      updateEditorHeight()
      setContent(value)
      if (onChange) {
        onChange(value)
      }
    }
  }

  const handleEditorDidMount = (editor: MonacoEditorType) => {
    editorRef.current = editor
    const domNode = editor.getDomNode()
    if (domNode) {
      domNode.addEventListener('wheel', handleScroll, { passive: false })
    }
  }

  const handleScroll = (event: WheelEvent) => {
    if (!editorRef.current) return

    const editor = editorRef.current
    const scrollTop = editor.getScrollTop()
    const scrollHeight = editor.getScrollHeight()
    const editorHeight = editor.getLayoutInfo().height

    // If at the top of the editor, allow the page to scroll up
    if (scrollTop <= 0 && event.deltaY < 0) {
      event.preventDefault() // Prevent editor scroll
      event.stopPropagation() // Allow page to scroll
    }
    // If at the bottom of the editor, allow the page to scroll down
    else if (scrollTop + editorHeight >= scrollHeight && event.deltaY > 0) {
      event.preventDefault() // Prevent editor scroll
      event.stopPropagation() // Allow page to scroll
    }
  }

  return (
    <Editor
      theme="customLightYamlTheme"
      language="yaml"
      options={{
        readOnly: readOnly,
        minimap: { enabled: false },
        formatOnPaste: true,
        folding: true,
        insertSpaces: true,
        wordWrap: 'on',
        fontSize: 16,
        fontFamily: 'monospace',
        tabSize: 2,
        lineNumbers: 'on',
        language: 'yaml',
      }}
      height={height}
      value={readOnly ? yamlContent : content}
      onMount={handleEditorDidMount}
      onChange={handleEditorChange}
      loading={<div>Loading editor...</div>}
    />
  )
}
