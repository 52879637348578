import * as React from 'react'
import styles from './SelfConfigComment.module.css'
import { Button } from '@/components/atoms/Button/Button'
import { Status } from '@/@types'
import { Flex, Text } from '@chakra-ui/react'
import { colors } from '@hivemq/ui-theme'

type Props = {
  cols?: number
  rows?: number
  name: string
  value: string
  onChange: (_: React.ChangeEvent<HTMLTextAreaElement>) => void
  onCancel: () => void
  onOk: () => void
  status: Status
}

export function SelfConfigComment({
  children,
  cols = 40,
  rows = 20,
  name,
  value,
  onChange,
  onCancel,
  onOk,
  status,
  ...props
}: React.PropsWithChildren<Props>) {
  return (
    <section>
      <textarea
        className={styles.selfConfigComment}
        onChange={onChange}
        name={name}
        value={value}
        cols={cols}
        rows={rows}
        {...props}
        placeholder="Describe your changes here"
      ></textarea>
      {children}

      {status === Status.Error && (
        <Flex justifyContent={''}>
          <Text color={colors.red[300]}>
            Error creating revision, please confirm your updates and try again
          </Text>
        </Flex>
      )}
      <div className={styles.selfConfigCommentActions}>
        <Button
          className={styles.selfConfigCommentDiscard}
          type="button"
          onClick={onCancel}
        >
          Discard draft
        </Button>
        <Button
          className={styles.selfConfigCommentSave}
          type="button"
          onClick={onOk}
          disabled={status === Status.Loading}
        >
          {status === Status.Loading ? 'Submitting...' : 'Save draft'}
        </Button>
      </div>
    </section>
  )
}
