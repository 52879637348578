import * as React from 'react'
import styles from './FilterSearch.module.css'
import { Button, Input } from '@chakra-ui/react'
import { colors } from '@hivemq/ui-theme'
import { useNavigate } from '@tanstack/react-router'

type Props = {
  options: Record<string, string>
  onToggleOptions: (_: React.MouseEvent) => void
  onChange: (_: React.ChangeEvent<HTMLInputElement>) => void
  onOptionsChange: (_: string) => void
  displayOptions: boolean
  name: string
  config: {
    filter: string
    text: string
  }
}

export function FilterSearch({
  options,
  onToggleOptions,
  onChange,
  displayOptions,
  onOptionsChange,
  config,
  name,
}: Props) {
  const nav = useNavigate()

  function onCreateNewConfig() {
    // todo: update to real path
    nav({
      to: '/clusters/new',
    })
  }

  return (
    <div className={styles.filter}>
      <div className={styles.filterFilter}>
        <Button
          className={styles.filterFilterButton}
          type="button"
          onClick={onToggleOptions}
        >
          Filters
        </Button>
        {displayOptions ? (
          <ul className={styles.filterFIlterUl}>
            {Object.entries(options).map(([key, title]) => (
              <li
                className={styles.filterFilterLi}
                key={key}
                onClick={() => onOptionsChange(key)}
              >
                {title}
              </li>
            ))}
          </ul>
        ) : null}
      </div>

      <Input
        type="text"
        value={config.text}
        name={name}
        width={'70%'}
        borderRadius={'6'}
        paddingX={'4'}
        paddingY={'2'}
        onChange={onChange}
        borderColor={colors.neutrals[200]}
      />

      <Button
        className={styles.filterTemplateButton}
        type="button"
        onClick={onCreateNewConfig}
        height={'6'}
        mr={'8'}
      >
        New Config
      </Button>
    </div>
  )
}
