import { ClusterCodeMgr } from '@/components/organisms/ClusterCodeMgr/ClusterCodeMgr'
import { ClusterConfigContext } from '@/stores/clusterConfig'
import { EditorStatus, SelfConfigContext } from '@/stores/selfconfig'
import * as React from 'react'
import { createRoute } from '@tanstack/react-router'
import { clusterConfigsTabRoute } from '../ClusterConfigsTab/ClusterConfigsTab'

export const clusterConfigDraftRoute = createRoute({
  getParentRoute: () => clusterConfigsTabRoute,
  path: 'draft',
  component: () => <ClusterConfigDraft />,
})

export function ClusterConfigDraft() {
  const { getTheme } = React.useContext(SelfConfigContext)

  const { onChangeCode, clusterConfigFiles } =
    React.useContext(ClusterConfigContext)

  return (
    <article>
      <ClusterCodeMgr
        onChange={onChangeCode}
        customOptions={getTheme(EditorStatus.ReadAndWrite)}
        configFiles={clusterConfigFiles}
      />
    </article>
  )
}
