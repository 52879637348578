import * as React from 'react'
import styles from './Sidebar.module.css'
import classnames from 'classnames'
import { Link, useMatches } from '@tanstack/react-router'
import { organizationRoute } from '@/features/Organisations/page/OrganizationTable'
import { identitySupportRoute } from '@/Pages/IdentitySupport/IdentitySupport'
import { clustersRoute } from '@/Pages/ClusterConfigs/ClusterConfigs'
import { templateTableRoute } from '@/features/Templates/page/TemplateTable'
import {
  clustersRootRoute,
  organizationRootRoute,
  templatesRootRoute,
} from '@/router/rootRoutes'
import { Icon } from '@chakra-ui/icons'
import { ExternalLink } from 'lucide-react'

export function Sidebar() {
  const matches = useMatches()

  const options = [
    {
      title: 'Hive Data',
      route: organizationRoute,
      rootRoute: organizationRootRoute,
    },
    {
      title: 'Identity Support',
      route: identitySupportRoute,
      rootRoute: identitySupportRoute,
    },
    {
      title: 'Cluster Configs',
      route: clustersRoute,
      rootRoute: clustersRootRoute,
    },
    {
      title: 'Templates',
      route: templateTableRoute,
      rootRoute: templatesRootRoute,
    },
  ]

  return (
    <nav className={styles.sidebar}>
      <ol className={styles.sidebarOl}>
        {options.map((option) => (
          <li key={option.title} className={styles.sidebarLi}>
            <Link
              to={option.route.to}
              className={classnames([
                styles.sidebarLink,
                matches.some((match) => match.routeId === option.rootRoute.id)
                  ? styles.sidebarLinkActive
                  : undefined,
              ])}
            >
              {option.title}
            </Link>
          </li>
        ))}
        <li key="doc" className={styles.sidebarLi}>
          <a
            href="https://docs.google.com/document/d/1z-snSivljxA-yn9F5eF5s7Ypi2MAmN7HR5IQU1oQh7Q/edit?tab=t.0"
            className={classnames(styles.sidebarLink)}
            target="doc"
          >
            Documentation
            <Icon ml="2" mb="2px" as={ExternalLink} w={4} h={4} />
          </a>
        </li>
      </ol>
    </nav>
  )
}
