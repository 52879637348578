import * as React from 'react'
import { Status } from '@/@types'
import { Hive, ModifiedHive, ModifiedHiveWithStatus } from '@/@types/hives'
import { HTTPMethod, useFetchCall } from '@/helpers/useFetchCall'
import { InsightKey, insightUrls } from '@/helpers/constants'
import { toTokenData } from '@/helpers/toTokenData'
import { ModifiedApiToken } from '@/@types/apiToken'

type State = {
  status: Status
  hive: ModifiedHiveWithStatus | null
  orgId: string | null
  token: {
    data: ModifiedApiToken | null
  }
}

type SpecificHive = {
  storeState: State
  setOrgId: (_: string) => void
  handleDeleteCluster: (_: string) => void
}

export const SpecificHiveContext = React.createContext<SpecificHive>(
  {} as SpecificHive
)

export function SpecificHiveProvider({ children }: React.PropsWithChildren) {
  const { fetchCall } = useFetchCall()

  const [storeState, setStoreState] = React.useState<State>({
    status: Status.Rest,
    orgId: null,
    hive: null,
    token: {
      data: null,
    },
  })

  React.useEffect(() => {
    if (storeState.orgId) {
      getHive()
    }
  }, [storeState.orgId])

  const modifyHiveData = (result: Hive): ModifiedHive<{ status: Status }> => ({
    ...result,
    org_id: {
      value: result.org_id,
      url: insightUrls[InsightKey.OrgId](),
    },
    users_info: result.users_info.map((user) => ({
      user_id: {
        value: user.user_id,
        url: insightUrls[InsightKey.Auth0](user.user_id),
      },
      stripe_id: {
        value: user.stripe_id,
        url: insightUrls[InsightKey.Stripe](user.stripe_id),
      },
    })),
    hives_info: result.hives_info.map((hive) => {
      const region = hive.apiary === 'a01' ? 'euc1' : 'usw2'
      return {
        ...hive,
        apiary: {
          argoCD: {
            value: 'View on Argo CD',
            url: insightUrls[InsightKey.ArgoCD](
              hive.apiary,
              region,
              hive.cluster_id
            ),
          },
          grafana: {
            value: 'View on Grafana',
            url: insightUrls[InsightKey.Grafana](hive.apiary, hive.cluster_id),
          },
        },
        status: Status.Rest,
      }
    }),
  })

  async function getHive() {
    try {
      setStoreState((state) => ({
        ...state,
        status: Status.Loading,
        hive: null,
      }))
      const getHiveData = (await fetchCall({
        path: `orgs/${storeState.orgId}/hives`,
        method: HTTPMethod.GET,
      })) as Hive

      const modifiedHive: ModifiedHiveWithStatus = modifyHiveData(getHiveData)

      const getApiKey = await fetchCall({
        path: `orgs/${storeState.orgId}/apikeys/internal`,
        method: HTTPMethod.GET,
        params: {
          customerId: window.btoa(modifiedHive.users_info[0].user_id.value),
        },
      })

      const apiToken = toTokenData(getApiKey?.token)
      setStoreState((state) => ({
        ...state,
        hive: modifiedHive,
        status: Status.Success,
        token: { data: apiToken },
      }))
    } catch (error) {
      setStoreState((state) => ({ ...state, status: Status.Error, hive: null }))
    }
  }

  async function handleDeleteCluster(clusterId: string) {
    try {
      setStoreState((state) => ({
        ...state,
        hive: {
          ...state.hive!,
          hives_info: state.hive!.hives_info.map((info) => ({
            ...info,
            status:
              info.cluster_id === clusterId ? Status.Loading : info.status,
          })),
        },
      }))

      await fetchCall({
        path: `orgs/${storeState.orgId}/clusters/${clusterId}`,
        method: HTTPMethod.DELETE,
      })

      setStoreState((state) => ({
        ...state,
        hive: {
          ...state.hive!,
          hives_info: state.hive!.hives_info.filter(
            (info) => info.cluster_id !== clusterId
          ),
        },
      }))
    } catch (error) {
      setStoreState((state) => ({
        ...state,
        hive: {
          ...state.hive!,
          hives_info: state.hive!.hives_info.map((info) => ({
            ...info,
            status: info.cluster_id === clusterId ? Status.Error : info.status,
          })),
        },
      }))
    }
  }

  function setOrgId(orgId: string) {
    return setStoreState((state) => ({ ...state, orgId }))
  }

  const value = React.useMemo(
    () => ({ storeState, setOrgId, handleDeleteCluster }),
    [storeState]
  )

  return (
    <SpecificHiveContext.Provider value={value}>
      {children}
    </SpecificHiveContext.Provider>
  )
}
