import { Button } from '@/components/atoms/Button/Button'
import { X } from 'lucide-react'
import * as React from 'react'
import styles from './ReviewDecision.module.css'
import { HiveAction } from '@/components/organisms/SelfConfigTab/SelfConfigTab'
import { RevisionType } from '@/@types'

export enum Page {
  Template = 'template',
  Cluster = 'cluster',
}

type Props = {
  name: string
  onClick: (_decision: RevisionType) => void
  onClose: () => void
  onChange: (_: React.ChangeEvent<HTMLTextAreaElement>) => void
  hiveAction: HiveAction
  value: string
  page?: Page
}

export function ReviewDecision({
  name,
  onClick,
  onClose,
  onChange,
  hiveAction,
  value,
  page = Page.Cluster,
}: Props) {
  function handleSubmit(action: RevisionType) {
    onClick(action)
  }
  const approveButtonAction =
    hiveAction === 'decide'
      ? RevisionType.Approve
      : (hiveAction as RevisionType)
  return (
    <article className={styles.decision}>
      <div className={styles.decisionTop}>
        <p className={styles.decisionTopTitle}>
          {hiveAction === 'decide' && 'Approve changes'}
          {['rollback', 'apply'].includes(hiveAction) && 'Confirm Action'}
        </p>
        <Button
          type="button"
          className={styles.decisionTopButton}
          onClick={onClose}
        >
          <X />
        </Button>
      </div>
      {hiveAction === 'decide' && (
        <div className={styles.decisionMid}>
          <textarea
            className={styles.decisionMidText}
            name={name}
            onChange={onChange}
            cols={30}
            rows={6}
            value={value}
          />
        </div>
      )}
      {['rollback', 'apply'].includes(hiveAction) && (
        <div>
          Are you sure you want to &nbsp;<strong>{hiveAction}</strong>&nbsp;
          this {page === Page.Cluster ? 'config' : 'template'}
        </div>
      )}
      <div className={styles.decisionBottom}>
        {hiveAction === 'decide' && (
          <Button
            className={styles.decisionBottomReject}
            type="button"
            onClick={() => handleSubmit(RevisionType.Reject)}
          >
            Reject
          </Button>
        )}
        <Button
          className={styles.decisionBottomApprove}
          type="button"
          onClick={() => handleSubmit(approveButtonAction)}
        >
          {hiveAction === 'decide' && 'Approve'}
          {['rollback', 'apply'].includes(hiveAction) && 'Submit'}
        </Button>
      </div>
    </article>
  )
}
