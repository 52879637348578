import { HiveState } from './clusterConfig'

declare global {
  export interface Window {
    hmqcStaticConfig: {
      VITE_ISSUER?: string
      VITE_CLIENT_ID?: string
      VITE_API_BASE_URL?: string
    }
  }
}

export enum Status {
  Rest = 'rest',
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}

export type ValueOf<T> = T[keyof T]

export enum RevisionType {
  Approve = 'approve',
  Reject = 'reject',
  Apply = 'apply',
  Rollback = 'rollback',
}

export const reviewAction: Record<HiveState, string> = {
  [HiveState.APPLIED]: 'rollback',
  [HiveState.SUBMITTED]: 'decide', // SHOULD SPAWN THE MODAL ASKING IF THEY WANT TO ACCEPT OR REJECT THE CHANGE
  [HiveState.APPROVED]: 'apply',
  [HiveState.REJECTED]: '',
} as const
