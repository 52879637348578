import * as React from 'react'
import styles from './Layout.module.css'
import { NavigationBar } from '@/components/organisms/NavigationBar/NavigationBar'
import { Sidebar } from '@/components/organisms/Sidebar/Sidebar'
import type { PropsWithChildren } from 'react'

export function Layout({ children }: PropsWithChildren) {
  return (
    <div className={styles.layout}>
      <header className={styles.layoutHeader}>
        <NavigationBar />
      </header>
      <main className={styles.layoutMain}>
        <aside className={styles.layoutSidebar}>
          <Sidebar />
        </aside>
        <article className={styles.layoutContent}>{children}</article>
      </main>
    </div>
  )
}
