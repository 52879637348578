import { ReactDiffEditor } from '@/components/organisms/DiffEditor/DiffEditor'
import { Box, Flex, Select, Text } from '@chakra-ui/react'
import * as React from 'react'

export type Props = {
  state: {
    base: string
    compare: string
  }
  onChange: (_: React.ChangeEvent<HTMLSelectElement>) => void
  options?: Array<{ name: string; id: string }>
  changes?: Array<{ data: string; modified: string; id: string | number }>
}

export function DynamicComparison({
  state,
  onChange,
  options,
  changes,
}: Props) {
  return (
    <Box as="article" width={'85%'}>
      <Flex width={'100%'} alignItems={'center'} flexDir={'column'} mb={'20'}>
        <Text fontSize={'xl'} fontWeight={'bold'} mb="10">
          Compares changes across revisions
        </Text>
        <Flex width={'50%'} gap={'3rem'}>
          {Object.entries(state).map(([key, value], index) => (
            <Select
              key={key}
              defaultValue={value}
              placeholder={index == 0 ? 'base' : 'compare'}
              name={key}
              onChange={onChange}
            >
              {options?.map((data) => (
                <option key={data.id} value={data.id}>
                  {data.name}
                </option>
              ))}
            </Select>
          ))}
        </Flex>
      </Flex>

      {changes?.map(({ data, modified, id }) => (
        <Box key={id} mb={'12'}>
          <ReactDiffEditor
            original={data}
            modified={modified}
            language="yaml"
          />
        </Box>
      ))}
    </Box>
  )
}
