import * as React from 'react'
import styles from './SelfConfigTab.module.css'
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
} from '@tanstack/react-router'
import { Button } from '@/components/atoms/Button/Button'
import classNames from 'classnames'
import { DisplayView, SelfConfigContext } from '@/stores/selfconfig'
import { SelfConfigComment } from '@/components/molecules/SelfConfigComment/SelfConfigComment'
import { Layers, Pencil, ScanEye } from 'lucide-react'
import { ClusterConfigContext } from '@/stores/clusterConfig'
import { RevisionsContext } from '@/stores/clusterRevisions'
import { ReviewDecision } from '@/components/molecules/ReviewDecision/ReviewDecision'
import { HiveState } from '@/@types/clusterConfig'
import { RevisionType, Status, ValueOf } from '@/@types'
import {
  Button as ChakraButton,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { clusterConfigsTabRoute } from '../../../Pages/ClusterConfigsTab/ClusterConfigsTab'

export enum View {
  Head = 'head',
  Revisions = 'revisions',
  Draft = 'draft',
}

type Props = {
  revisionCount: number
  versionName: string
  lastUpdated: string
  author: string
  displayMeta?: Array<string>
}

const reviewAction: Record<HiveState, string> = {
  [HiveState.APPLIED]: 'rollback',
  [HiveState.SUBMITTED]: 'decide', // SHOULD SPAWN THE MODAL ASKING IF THEY WANT TO ACCEPT OR REJECT THE CHANGE
  [HiveState.APPROVED]: 'apply',
  [HiveState.REJECTED]: '',
} as const

export type HiveAction = ValueOf<typeof reviewAction>

export function SelfConfigTab({ versionName, author, lastUpdated }: Props) {
  const {
    tabs,
    state: configState,
    onChangeDisplay,
    onClickEditHead,
  } = React.useContext(SelfConfigContext)
  const {
    state: clusterState,
    createConfigRevision,
    resetUpdateStatus,
  } = React.useContext(ClusterConfigContext)
  const { state: revisionState, reviewChanges } =
    React.useContext(RevisionsContext)
  const changesMade =
    JSON.stringify(clusterState.specificCluster.data) !=
    JSON.stringify(clusterState.specificCluster.modifiedData)

  const params = clusterConfigsTabRoute.useParams()

  const location = useLocation()
  const nav = useNavigate()

  const pathName = location.pathname.split('/')
  const configType = pathName.filter((path) => !!path)?.at(0)
  const activeTab = pathName.at(-1)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const clusterWithAppliedConfig =
    clusterState.specificCluster.status === Status.Success &&
    !!clusterState.specificCluster.data
  /** Should not display the edit button on any page that has the strings below in its pathname */
  const displayEditButton =
    ['draft', 'files', 'revisions', 'new', 'compare'].filter((path) =>
      pathName.includes(path)
    ).length == 0
  const displayView = (() => {
    if (
      ['files', 'revisions', 'new', 'compare'].filter((path) =>
        pathName.includes(path)
      ).length === 0
    ) {
      return true
    }
    return false
  })()

  const currentConfig = revisionState?.list.data?.find(
    // @ts-expect-error - that part is strait up broken in the first place | revisionId does not exist at all
    (revision) => revision.revision_id == params.revisionId
  )?.approval_state.state as unknown as HiveState

  const reviewMetaData = React.useMemo(() => {
    // @ts-expect-error - that part is strait up broken in the first place | revisionId does not exist at all
    if (params.revisionId) {
      const totalApplied = revisionState?.list.data?.filter(
        (data) => data.approval_state.state === HiveState.APPLIED
      )

      const onlyAppliedConfigintheList =
        totalApplied?.length == 1 &&
        // @ts-expect-error - that part is strait up broken in the first place | revisionId does not exist at all
        totalApplied[0]?.revision_id === params.revisionId

      const rolledbackRevision =
        currentConfig === HiveState.APPLIED &&
        // @ts-expect-error - that part is strait up broken in the first place | revisionId does not exist at all
        params.revisionId != clusterState.specificCluster.data?.revision_id

      const rejectedRevision = currentConfig === HiveState.REJECTED
      if (
        !onlyAppliedConfigintheList &&
        !rolledbackRevision &&
        !rejectedRevision
      ) {
        return {
          reviewPage: true,
          status: reviewAction[currentConfig as unknown as HiveState],
        }
      }
    }

    return {
      reviewPage: false,
    }
    // @ts-expect-error - that part is strait up broken in the first place | revisionId does not exist at all
  }, [params.revisionId, revisionState, clusterState])

  const [state, setState] = React.useState({
    displayReview: false,
    displayDecision: false,
    comment: '',
    decision: '',
  })

  function onReviewChanges() {
    nav({
      to: '/clusters/$orgId/$clusterId/revisions',
      params: {
        orgId: params.orgId,
        clusterId: params.clusterId,
      },
    })
  }

  function onCancel() {
    if (configType === 'clusters') {
      nav({
        to: '/clusters',
      })
    }
  }

  function onClickCompare() {
    nav({
      to: '/clusters/$orgId/$clusterId/compare',
      params: {
        orgId: params.orgId,
        clusterId: params.clusterId,
      },
    })
  }

  React.useEffect(() => {
    if (revisionState.update.status === Status.Success) {
      setState((state) => ({
        ...state,
        comment: '',
        decision: '',
        displayDecision: false,
      }))
      onClose()
    }
  }, [revisionState.update.status])

  React.useEffect(() => {
    resetUpdateStatus()
  }, [location])

  function decideOnChanges(type: RevisionType) {
    // @ts-expect-error - that part is strait up broken in the first place | revisionId does not exist at all
    if (params.orgId && params.clusterId && params.revisionId) {
      // @ts-expect-error - that part is strait up broken in the first place | revisionId does not exist at all
      reviewChanges(params.orgId, params.clusterId, params.revisionId, type)
    }
  }

  function onChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const { name, value } = event.target
    setState((state) => ({ ...state, [name]: value }))
  }

  const displayReviewChangesButton =
    Object.keys(tabs).length === 3 &&
    activeTab == tabs['draft'].path &&
    !state.displayReview

  const isLayers = configState.display === DisplayView.Layers

  return (
    <section className={styles.selfConfig}>
      <Tabs colorScheme={'#FED37F'}>
        <TabList className={styles.selfConfigUl}>
          {Object.entries(tabs).map(([title, { path, key }]) => (
            <Tab
              key={title}
              className={classNames(styles.selfConfigTab, {
                [styles.selfConfigActiveTab]: key == activeTab,
              })}
            >
              <Link to={path} className={styles.selfConfigTabA}>
                <Text
                  fontSize={'lg'}
                  textTransform={'capitalize'}
                  fontWeight={'bold'}
                >
                  {title}
                </Text>
              </Link>
            </Tab>
          ))}
        </TabList>
        <Flex justifyContent={'space-between'} w={'85%'}>
          <div className={styles.selfConfigMeta}>
            <div className={styles.selfConfigMetaData}>
              <div className={styles.selfConfigMetaDataBio}>
                {displayEditButton ? (
                  <p>
                    Applied {lastUpdated} by{' '}
                    <span className={styles.selfConfigMetaDataHighlight}>
                      {author}
                    </span>
                  </p>
                ) : null}
                {activeTab == tabs['draft']?.path && (
                  <p>
                    Based on{' '}
                    <span className={styles.selfConfigMetaDataHighlight}>
                      {versionName}&nbsp;(head)
                    </span>
                  </p>
                )}
              </div>
            </div>
            <div className={styles.selfConfigAction}>
              {displayReviewChangesButton ? (
                <Button
                  type="button"
                  className={styles.selfConfigActionReview}
                  onClick={onReviewChanges}
                  disabled={!changesMade}
                >
                  Review Changes
                </Button>
              ) : null}
            </div>

            {reviewMetaData?.reviewPage ? (
              <Button type="button" onClick={() => onOpen()}>
                <Text textTransform={'capitalize'}>
                  {reviewMetaData.status}
                </Text>
              </Button>
            ) : null}
          </div>

          {activeTab === 'revisions' ? (
            <ChakraButton
              type="button"
              variant={'primary'}
              size={'sm'}
              onClick={onClickCompare}
            >
              Compare Changes
            </ChakraButton>
          ) : null}
        </Flex>
      </Tabs>
      <div className={styles.selConfigDisplay}>
        {displayView ? (
          <div className={styles.selConfigDisplayActions}>
            <Button
              type="button"
              onClick={() => onChangeDisplay(DisplayView.Layers)}
              className={classNames(styles.selfConfigDisplayButon, {
                [styles.selfConfigDisplayButonActive]: isLayers,
              })}
            >
              <Layers />
              <span>Layers</span>
            </Button>
            <Button
              type="button"
              onClick={() => onChangeDisplay(DisplayView.Preview)}
              className={classNames(styles.selfConfigDisplayButon, {
                [styles.selfConfigDisplayButonActive]: !isLayers,
              })}
            >
              <ScanEye />
              <span>Previews</span>
            </Button>
          </div>
        ) : null}

        <Flex>
          {displayEditButton && clusterWithAppliedConfig ? (
            <Button
              type="button"
              onClick={onClickEditHead}
              className={styles.selConfigDisplayEdit}
            >
              <Pencil size={20} />
              <span>Edit config</span>
            </Button>
          ) : null}

          {displayEditButton && !clusterWithAppliedConfig ? (
            <Button
              type="button"
              onClick={onClickEditHead}
              className={styles.selConfigDisplayEdit}
            >
              <Pencil size={20} />
              <span>Create config</span>
            </Button>
          ) : null}
        </Flex>
      </div>
      {activeTab === 'files' ? (
        <div className={styles.selfConfigComment}>
          <SelfConfigComment
            onChange={onChange}
            name="comment"
            value={state.comment}
            status={clusterState.update}
            cols={50}
            rows={5}
            onCancel={onCancel}
            onOk={() =>
              createConfigRevision(
                params?.orgId ?? '',
                params?.clusterId ?? '',
                state.comment
              )
            }
          />
        </div>
      ) : null}
      {reviewMetaData.status ? (
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay
            bg="blackAlpha.300"
            backdropFilter="blur(10px) hue-rotate(90deg)"
          />
          <ModalContent>
            <ReviewDecision
              name="decision"
              onClick={decideOnChanges}
              onClose={onClose}
              onChange={onChange}
              hiveAction={reviewMetaData.status}
              value={state.decision}
            />
          </ModalContent>
        </Modal>
      ) : null}
    </section>
  )
}
