import { ApiToken, ModifiedApiToken } from '@/@types/apiToken'
import { jwtDecode } from 'jwt-decode'

export function toTokenData(input: string): ModifiedApiToken {
  const decodedToken = jwtDecode(input) as ApiToken

  //TODO @RobinAtherton - Maybe there are more values inside here that would be useful for a details page
  const data = {
    Expiry: new Date(decodedToken.exp * 1000).toLocaleString(),
    Token: input,
  }

  return data
}
