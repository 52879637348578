import { RevisionType, Status } from '@/@types'
import { ClusterRevisionChanges } from '@/@types/clusterConfig'
import { separateYamlFromJson } from '@/helpers/separateYamlFromJson'
import {
  ApiVersion,
  ErrorMessage,
  HTTPMethod,
  useFetchCall,
} from '@/helpers/useFetchCall'
import * as React from 'react'
import { useNavigate, useParams } from '@tanstack/react-router'
import { ClusterConfigContext } from '../clusterConfig'
import { HiveAction } from '@/components/organisms/SelfConfigTab/SelfConfigTab'
import { Revision, Template } from '@/generated/http-clients/v3ApiSchemas'

type RevisionsData = {
  state: ClusterRevisionChanges
  getRevisionById(
    orgId: string,
    clusterId: string,
    revisionId: string
  ): Promise<void>
  reviewChanges(
    _orgId: string,
    _clusterId: string,
    _revisionId: string,
    _revision: RevisionType
  ): Promise<void>
}

const defaultData: ClusterRevisionChanges = {
  list: { status: Status.Rest, data: null },
  update: { status: Status.Rest },
  detailed: {},
}

export const RevisionsContext = React.createContext<RevisionsData>({
  state: defaultData,
} as RevisionsData)

export function RevisionProvider({ children }: React.PropsWithChildren) {
  const { fetchCall } = useFetchCall()
  const { state: clusterState, getConfig } =
    React.useContext(ClusterConfigContext)

  // todo: make this more robust
  const params = useParams({ strict: false })
  const nav = useNavigate()

  const [state, setState] = React.useState<ClusterRevisionChanges>(defaultData)

  React.useEffect(() => {
    if (!params.orgId || !params.clusterId) return

    getAllRevisions(params.orgId, params.clusterId)
  }, [clusterState.update, params])

  React.useEffect(() => {
    if (params.orgId && params.clusterId && clusterState.specificCluster.data) {
      getRevisionById(
        params.orgId,
        params.clusterId,
        clusterState.specificCluster.data.revision_id
      )
    }
  }, [clusterState.specificCluster.data])

  async function getAllRevisions(orgId: string, clusterId: string) {
    try {
      setState((state) => ({
        ...state,
        list: { status: Status.Loading, data: null },
      }))
      const data = (await fetchCall<Array<Revision>>({
        path: `orgs/${orgId}/clusters/${clusterId}/revisions`,
        method: HTTPMethod.GET,
        version: ApiVersion.V3,
      }))!

      data!.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )

      setState((state) => ({
        ...state,
        list: { status: Status.Success, data },
      }))
    } catch (error) {
      const errorMessage = (error as unknown as Error)?.message
      if (errorMessage === ErrorMessage.NotFound) {
        setState((state) => ({
          ...state,
          list: { status: Status.Success, data: [] },
        }))
        return
      }
      setState((state) => ({
        ...state,
        list: { status: Status.Error, data: null },
      }))
    }
  }

  async function getRevisionById(
    orgId: string,
    clusterId: string,
    revisionId: string
  ) {
    try {
      setState((state) => ({
        ...state,
        detailed: {
          ...state.detailed,
          [revisionId]: {
            status: Status.Loading,
            data: null,
            display: null,
          },
        },
      }))
      const data = (await fetchCall<Template>({
        path: `orgs/${orgId}/clusters/${clusterId}/revision/${revisionId}/render`,
        method: HTTPMethod.GET,
        version: ApiVersion.V3,
      }))!

      const displayData = separateYamlFromJson(data)!

      setState((state) => ({
        ...state,
        detailed: {
          ...state.detailed,
          [revisionId]: {
            status: Status.Success,
            data,
            display: displayData,
          },
        },
      }))
    } catch (error) {
      setState((state) => ({
        ...state,
        detailed: {
          ...state.detailed,
          [revisionId]: {
            status: Status.Error,
            data: null,
            display: null,
          },
        },
      }))
    }
  }

  async function reviewChanges(
    orgId: string,
    clusterId: string,
    revisionId: string,
    revision: RevisionType | HiveAction
  ) {
    try {
      setState((state) => ({ ...state, update: { status: Status.Loading } }))
      await fetchCall({
        path: `orgs/${orgId}/clusters/${clusterId}/revisions/${revisionId}/${revision}`,
        method: HTTPMethod.PUT,
        version: ApiVersion.V3,
        payload: {
          comment: '',
        },
      })

      setState((state) => ({ ...state, update: { status: Status.Success } }))

      setTimeout(() => {
        // Sometimes: It takes time after an update before the change is reflected
        getAllRevisions(orgId, clusterId)
        if (
          [RevisionType.Apply, RevisionType.Rollback].includes(
            revision as RevisionType
          )
        ) {
          getConfig(orgId, clusterId)
        }

        // todo: update to real path
        nav({
          to: `clusters/${orgId}/${clusterId}/revisions`,
        })
        setState((state) => ({ ...state, update: { status: Status.Rest } }))
      }, 1500)
    } catch (error) {
      setState((state) => ({ ...state, update: { status: Status.Error } }))
    }
  }

  const value = React.useMemo(
    () => ({ state, getRevisionById, reviewChanges }),
    [state.detailed, state.update.status, state.list.status]
  )

  return (
    <RevisionsContext.Provider value={value}>
      {children}
    </RevisionsContext.Provider>
  )
}
