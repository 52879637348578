import '@/i18n'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security, useOktaAuth } from '@okta/okta-react'
import { RouterProvider } from '@tanstack/react-router'
import React, { useCallback, type PropsWithChildren } from 'react'
import ReactDOM from 'react-dom/client'
import { router } from './router'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const oktaAuth = new OktaAuth({
  clientId:
    import.meta.env.VITE_CLIENT_ID || window?.hmqcStaticConfig?.VITE_CLIENT_ID,
  issuer:
    import.meta.env.VITE_ISSUER || window?.hmqcStaticConfig?.VITE_ISSUER || '',
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
})

root.render(
  <InstallSecurityAuthentication>
    <React.StrictMode>
      <InstallRouter />
    </React.StrictMode>
  </InstallSecurityAuthentication>
)

function InstallSecurityAuthentication({ children }: PropsWithChildren) {
  // In React StrictMode, the following warning will be logged to the console:
  // Two custom restoreOriginalUri callbacks are detected.
  // The one from the OktaAuth configuration will be overridden by
  // the provided restoreOriginalUri prop from the Security component.
  // For that we memoize it with useCallback
  const restoreOriginalUri = useCallback((_: unknown, originalUri: string) => {
    window.history.pushState(
      null,
      '',
      toRelativeUrl(originalUri, window.location.origin)
    )
  }, [])

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  )
}

// NOTE: This is a workaround to access the oktaAuth object in the App component
function InstallRouter() {
  const auth = useOktaAuth()

  return <RouterProvider router={router} context={{ auth }} />
}
