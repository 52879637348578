export enum InsightKey {
  Stripe,
  Auth0,
  OrgId,
  Grafana,
  ArgoCD,
  NumberOfSessions,
  NormalizedMessages,
}

export const insightUrls = {
  [InsightKey.Stripe]: (stripeId: string) =>
    `https://dashboard.stripe.com/customers/${stripeId}`,
  [InsightKey.Auth0]: (userId: string) =>
    `https://manage.auth0.com/dashboard/eu/hivemq-cloud/users/${userId}`,
  [InsightKey.OrgId]: () =>
    'https://grafana.hmqc.dev/d/1a_R8_r4k/hive-dashboard?orgId=1',
  [InsightKey.ArgoCD]: (apiary: string, region: string, clusterId: string) =>
    `https://argocd.${apiary}.${region}.aws.hivemq.cloud/applications/apiary-hives/${clusterId}`,
  [InsightKey.Grafana]: (apiary: string, clusterId: string) =>
    `https://grafana.hmqc.dev/d/ML74c76mb/hivemq-cluster-v2?orgId=1&var-datasource=PE016382F9B8F1892&var-diskdevice=nvme1n1&var-deployment=apiary-${apiary}-prod-eu-central-1&var-job=${clusterId}&var-namespace=${clusterId}`,
  [InsightKey.NumberOfSessions]: (clusterId: string) =>
    `https://grafana.hmqc.dev/explore?orgId=1&panes=%7B%229ZI%22:%7B%22datasource%22:%22PFC17286A489AD829%22,%22queries%22:%5B%7B%22refId%22:%22A%22,%22expr%22:%22sum%20by%28namespace%29%20%28com_hivemq_sessions_overall_current%7Bnamespace%3D%5C%22${clusterId}%5C%22%7D%29%20%2F%202%22,%22range%22:true,%22datasource%22:%7B%22type%22:%22prometheus%22,%22uid%22:%22PFC17286A489AD829%22%7D,%22editorMode%22:%22builder%22,%22instant%22:false,%22exemplar%22:false,%22format%22:%22time_series%22,%22legendFormat%22:%22__auto%22,%22useBackend%22:false,%22disableTextWrap%22:false,%22fullMetaSearch%22:false,%22includeNullMetadata%22:true%7D%5D,%22range%22:%7B%22from%22:%22now-60d%22,%22to%22:%22now%22%7D%7D%7D&schemaVersion=1`,
  [InsightKey.NormalizedMessages]: (clusterId: string) =>
    `https://grafana.hmqc.dev/explore?orgId=1&panes=%7B%22rjT%22:%7B%22datasource%22:%22PFC17286A489AD829%22,%22queries%22:%5B%7B%22refId%22:%22A%22,%22expr%22:%22sum%20by%28namespace%29%20%28com_hivemq_cloud_normalized_messages_total%7Bnamespace%3D%5C%22${clusterId}%5C%22%7D%29%22,%22range%22:true,%22datasource%22:%7B%22type%22:%22prometheus%22,%22uid%22:%22PFC17286A489AD829%22%7D,%22editorMode%22:%22builder%22,%22instant%22:false,%22exemplar%22:false,%22format%22:%22time_series%22,%22legendFormat%22:%22__auto%22%7D%5D,%22range%22:%7B%22from%22:%22now-60d%22,%22to%22:%22now%22%7D%7D%7D&schemaVersion=1`,
}
