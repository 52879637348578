import * as React from 'react'
import * as monaco from 'monaco-editor'
import { useLocation, useNavigate, useParams } from '@tanstack/react-router'

// this store should eventually become just a hook, there's no reason for this to be a store
type MonacoOptions = monaco.editor.IStandaloneEditorConstructionOptions
type SelfConfig = {
  onClickEditHead: () => void
  tabs: Record<string, { path: string; key: string }>
  state: { display: DisplayView }
  getTheme: (status: EditorStatus) => Partial<MonacoOptions>
  onChangeDisplay: (_: DisplayView) => void
  isDraftPage: boolean
}

export enum EditorStatus {
  ReadOnly,
  ReadAndWrite,
}

export const SelfConfigContext = React.createContext<SelfConfig>({
  tabs: {},
  state: {},
} as SelfConfig)

export enum DisplayView {
  Layers = 'layers',
  Preview = 'preview',
}

export function SelfConfigProvider({ children }: React.PropsWithChildren) {
  const tabCompatibleRoots = ['templates', 'clusters']
  const location = useLocation()
  const isDraftPage = location.pathname.split('/').at(-1) === 'draft'
  const root = location.pathname.split('/').filter((path) => !!path)[0]
  const defaultTabs = {
    head: { path: '', key: root },
    revisions: { path: 'revisions', key: 'revisions' },
  }

  const [tabs, setTabs] = React.useState<SelfConfig['tabs']>(defaultTabs)

  React.useEffect(() => {
    if (tabCompatibleRoots.includes(root)) {
      setTabs(defaultTabs)
    }
    if (isDraftPage) {
      setTabs((tabs) => ({ ...tabs, draft: { path: 'draft', key: 'draft' } }))
    }
  }, [root])

  const [state, setState] = React.useState<SelfConfig['state']>({
    display: DisplayView.Layers,
  })

  const nav = useNavigate()

  // todo: make this more robust
  const params = useParams({ strict: false })

  const onClickEditHead = React.useCallback(() => {
    if (Object.keys(tabs).length !== 3) {
      setTabs((state) => ({
        ...state,
        draft: { path: 'draft', key: 'draft' },
      }))
    }

    if (root == 'templates') {
      // todo: update to real path
      nav({
        to: `templates/${params.templateId}/draft`,
      })
      return
    }

    // todo: update to real path
    nav({
      to: `clusters/${params.orgId}/${params.clusterId}/draft`,
    })
  }, [params])

  function onChangeDisplay(display: DisplayView) {
    setState((state) => ({ ...state, display }))
  }

  function getTheme(status: EditorStatus) {
    return {
      readOnly: status === EditorStatus.ReadOnly,
      language: 'yaml',
      automaticLayout: true,
      scrollBeyondLastLine: false,
      tabSize: 4,
      insertSpaces: true,
    }
  }

  const value = React.useMemo(
    () => ({
      onClickEditHead,
      tabs,
      state,
      getTheme,
      onChangeDisplay,
      isDraftPage,
    }),
    [tabs, state, isDraftPage, params]
  )

  return (
    <SelfConfigContext.Provider value={value}>
      {children}
    </SelfConfigContext.Provider>
  )
}
