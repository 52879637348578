import YAML from 'yaml'
import { ClusterConfigContext } from '@/stores/clusterConfig'
import { EditorStatus, SelfConfigContext } from '@/stores/selfconfig'
import * as React from 'react'
import { RevisionsContext } from '@/stores/clusterRevisions'
import { HiveState } from '@/@types/clusterConfig'
import { ReactEditor } from '@/components/organisms/Editor/Editor'
import { ReactDiffEditor } from '@/components/organisms/DiffEditor/DiffEditor'
import { Box } from '@chakra-ui/react'
import { clusterConfigsTabRoute } from '../ClusterConfigsTab/ClusterConfigsTab'
import { createRoute, useNavigate } from '@tanstack/react-router'

export const clusterConfigDiffRoute = createRoute({
  getParentRoute: () => clusterConfigsTabRoute,
  path: 'files/$revisionId',
  component: () => <CodeDiff />,
})

export function CodeDiff() {
  const {
    state: { specificCluster },
  } = React.useContext(ClusterConfigContext)
  const { getRevisionById, state: revisionState } =
    React.useContext(RevisionsContext)

  const { getTheme } = React.useContext(SelfConfigContext)
  const nav = useNavigate()
  const params = clusterConfigDiffRoute.useParams()
  const revisionId = params?.revisionId

  const activeTemplateRevisionId = specificCluster.data?.revision_id

  React.useEffect(() => {
    if (specificCluster.data === specificCluster.modifiedData && !revisionId) {
      nav({
        to: '/',
      })
    }
    if (revisionId) {
      getRevisionById(params.orgId!, params.clusterId!, params.revisionId!)
    }
  }, [])

  const changes = React.useMemo(() => {
    if (revisionId) {
      return [
        {
          data: YAML.stringify(
            revisionState.detailed[activeTemplateRevisionId || revisionId]
              ?.display?.data
          ),
          modifiedData: YAML.stringify(
            revisionState.detailed[revisionId]?.display?.data
          ),
          language: 'yaml',
          id: 0,
        },
        {
          data: revisionState.detailed[activeTemplateRevisionId || revisionId]
            ?.display?.yaml,
          modifiedData: revisionState.detailed[revisionId]?.display?.yaml,
          language: 'yaml',
          id: 1,
        },
      ]
    }
    return [
      {
        data: YAML.stringify(specificCluster.data),
        modifiedData: YAML.stringify(specificCluster.modifiedData),
        language: 'yaml',
        id: revisionId,
      },
    ]
  }, [specificCluster, revisionState.detailed])

  const displayEditorOnly = React.useMemo(() => {
    if (revisionId) {
      const noAppliedRevision = !revisionState.list.data?.some(
        (revision) => revision.approval_state.state === HiveState.APPLIED
      )
      const isAppliedRevision =
        revisionState.list.data?.find(
          (revision) => revision.revision_id === revisionId
        )?.approval_state.state === HiveState.APPLIED
      if (noAppliedRevision || (revisionId && isAppliedRevision)) {
        return true
      }
    }

    return false
  }, [specificCluster, revisionState])

  return (
    <Box as="article" w={'85%'} mt={'4'}>
      {displayEditorOnly
        ? changes?.map(({ data, modifiedData, language, id }) => (
            <Box mt={'8'} key={id}>
              <ReactEditor
                language={language}
                value={data || modifiedData}
                options={getTheme(EditorStatus.ReadOnly)}
              />
            </Box>
          ))
        : null}

      {!displayEditorOnly &&
        changes?.map(
          ({ data, modifiedData, language, id }) =>
            data && (
              <Box mt={'8'} key={id}>
                <ReactDiffEditor
                  key={id}
                  original={data}
                  modified={modifiedData}
                  language={language}
                />
              </Box>
            )
        )}
    </Box>
  )
}
