import { Status } from '@/@types'
import { FilterSearch } from '@/components/molecules/FilterSearch/FilterSearch'
import { Table, TableRow } from '@/components/templates/Table/Table'
import { ClusterConfigContext } from '@/stores/clusterConfig'
import { Box } from '@chakra-ui/react'
import { createRoute, useNavigate } from '@tanstack/react-router'
import { Loader } from 'lucide-react'
import * as React from 'react'
import { clustersRootRoute } from '../../router/rootRoutes'
import styles from './ClusterConfigs.module.css'

const filterStates = {
  submitted: 'Submitted',
  approved: 'Approved',
  rejected: 'Rejected',
  applied: 'Applied',
  rolledback: 'RolledBack',
  none: 'None',
} as const

type Filters = keyof typeof filterStates

const headers = [
  { keyName: 'clusterId', title: 'Cluster Id' },
  { keyName: 'orgId', title: 'Org' },
  { keyName: 'status', title: 'State' },
]

type State = {
  displayFilter: boolean
  config: {
    filter: string
    text: string
  } & Record<string, string>
}

export const clustersRoute = createRoute({
  getParentRoute: () => clustersRootRoute,
  path: '/',
  component: () => <ClusterConfigsRoute />,
})

export function ClusterConfigsRoute() {
  const pageRef = React.useRef<HTMLElement>(null)
  const nav = useNavigate()
  const { state: clusterState } = React.useContext(ClusterConfigContext)

  const [state, setState] = React.useState<State>({
    displayFilter: false,
    config: {
      filter: filterStates['none'],
      text: '',
      owner: '',
      org: '',
      sort: 'Descending',
    },
  })

  function onToggleOptions(event?: React.MouseEvent) {
    event?.preventDefault()
    setState((state) => ({ ...state, displayFilter: !state.displayFilter }))
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setState((state) => ({
      ...state,
      config: { ...state.config, text: event.target.value },
    }))
  }

  React.useEffect(() => {
    let text = `filter: ${
      filterStates[state.config.filter as unknown as Filters] as string
    }`

    if (state.config.filter === 'none' || text.includes('undefined')) {
      text = ''
    }

    setState((state) => ({
      ...state,
      displayFilter: false,
      config: { ...state.config, text },
    }))
  }, [state.config.filter])

  function onChangeFilter(filter: string) {
    setState((state) => ({
      ...state,
      displayFilter: false,
      config: { ...state.config, filter },
    }))
  }

  const filteredClusterState = React.useMemo(() => {
    const searchText = state.config.text.toLowerCase()
    return clusterState.clusters.data?.filter(
      (cluster) =>
        cluster.revision.hive_id.toLowerCase().includes(searchText) ||
        cluster.revision.hive_name.toLowerCase().includes(searchText) ||
        cluster.revision.org_id.toLowerCase().includes(searchText)
    )
  }, [clusterState.clusters.data, state.config.text])

  const rows = React.useMemo(
    () =>
      filteredClusterState?.map(({ revision }) => ({
        rowKey: revision.hive_id,
        columns: {
          clusterId: {
            value: revision.hive_id,
          },
          orgId: {
            value: revision.org_id,
          },
          status: {
            value: revision?.hive_state?.state?.toLowerCase() || '',
          },
        },
      })),
    [filteredClusterState]
  )

  function handleClick(event: React.MouseEvent<HTMLElement>, row: TableRow) {
    // todo: update to real path
    nav({
      to: `${row.columns['orgId'].value}/${row.columns['clusterId'].value}`,
    })
  }

  return (
    <article ref={pageRef}>
      <div className={styles.configSearch}>
        <FilterSearch
          options={filterStates}
          onToggleOptions={onToggleOptions}
          onChange={onChange}
          displayOptions={state.displayFilter}
          onOptionsChange={onChangeFilter}
          config={state.config}
          name="search"
        />
      </div>

      <div className={styles.configTable}>
        {clusterState.clusters.status === Status.Loading && <Loader />}
        {filteredClusterState?.length && rows ? (
          <Box width={'85%'}>
            <Table headers={headers} rows={rows} onClick={handleClick} />
          </Box>
        ) : null}
        {clusterState.clusters.data?.length && !filteredClusterState?.length ? (
          <p>
            Could not find any cluster with name/hiveid containing{' '}
            <strong>{state.config.text}</strong>
          </p>
        ) : null}
        {clusterState.clusters.status === Status.Error && (
          <p>Error Loading Clusters</p>
        )}
      </div>
    </article>
  )
}
