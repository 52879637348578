import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import {
  Button,
  FormLabel,
  Input,
  VStack,
  Text,
  FormControl,
} from '@chakra-ui/react'
import { CreatableSelect } from 'chakra-react-select'
import { SectionCard } from '@/components/SectionCard/SectionCard'
import React, { useState } from 'react'
import { TemplateEditor } from '@/features/Templates/components/TemplateEditor'
import { colors } from '@hivemq/ui-theme'
import { Plus } from 'lucide-react'
import {
  useGetTags,
  usePostTemplate,
} from '@/generated/http-clients/v3ApiComponents'
import { z } from 'zod'
import { createRoute, useNavigate } from '@tanstack/react-router'
import { templatesRootRoute } from '@/router/rootRoutes'
import { NewTemplate } from '@/generated/http-clients/v3ApiSchemas'
import { templateTableRoute } from '@/features/Templates/page/TemplateTable'

export const templateCreateRoute = createRoute({
  getParentRoute: () => templatesRootRoute,
  path: 'create',
  component: () => <TemplateCreate />,
})
const formSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  description: z.string().optional(),
  chart_source: z.object({
    chart_path: z.string().min(1, 'Chart Path is required'),
    chart_repo_url: z
      .string()
      .min(1, 'Repository URL is required.')
      .url('Repository URL must be a valid URL'),
    chart_target_revision: z.string().min(1, 'Target Revision is required'),
  }),
  tags: z.array(z.string()).optional(),
  yaml: z.string().min(1, 'YAML content is required'),
})

export function TemplateCreate() {
  const [formValues, setFormValues] = useState<NewTemplate>({
    name: '',
    description: '',
    tags: [],
    chart_source: {
      chart_path: '',
      chart_repo_url: '',
      chart_target_revision: '',
    },
    yaml: '',
  })

  const [errors, setErrors] = useState<Record<string, string>>({})
  const createTemplate = usePostTemplate()
  /**
   * FIXME Please remove the tags input after Lev has fixed 500 response for non-filter requests
   */
  const tags = useGetTags({
    queryParams: {
      tags: 'Prod',
    },
  })
  const navigate = useNavigate()

  function submitCreateTemplate() {
    // Validate form values
    const validationResult = formSchema.safeParse(formValues)

    if (!validationResult.success) {
      const validationErrors: Record<string, string> = {}
      validationResult.error.errors.forEach((err) => {
        if (err.path.length) {
          let key = err.path.join('.')
          // @RobinAtherton - Remove "chart_source." prefix for its children, this makes it alot easier to map the errors.
          if (key.startsWith('chart_source.')) {
            key = key.replace('chart_source.', '')
          }
          validationErrors[key] = err.message
        }
      })
      setErrors(validationErrors)
      return
    }

    setErrors({})

    createTemplate
      .mutateAsync({
        body: formValues,
      })
      .then(() => navigate({ to: templateTableRoute.to }))
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    setFormValues((prevValues) => {
      if (name in prevValues.chart_source) {
        return {
          ...prevValues,
          chart_source: {
            ...prevValues.chart_source,
            [name]: value,
          },
        }
      }
      return {
        ...prevValues,
        [name]: value,
      }
    })
  }

  const handleChangeYaml = (yaml: string | undefined) => {
    if (yaml) {
      setFormValues((prevValues) => ({
        ...prevValues,
        yaml: yaml,
      }))
    }
  }

  return (
    <PageLayout
      title={'Create Template'}
      action={
        <Button
          backgroundColor={colors.amber[300]}
          borderRadius={'8'}
          padding={'1 0'}
          gap={'1'}
          borderWidth={'1px'}
          onClick={submitCreateTemplate}
        >
          <Plus />
          Create Template
        </Button>
      }
    >
      <SectionCard title={'Add template information'}>
        <VStack alignItems={'start'}>
          <FormControl isRequired isInvalid={!!errors.name}>
            <FormLabel mb={0}>Name</FormLabel>
            <Input
              id={'name'}
              name={'name'}
              placeholder={'Enter a name for your template'}
              value={formValues.name}
              onChange={handleInputChange}
            />
            {errors.name ? <Text color="red.500">{errors.name}</Text> : null}
          </FormControl>

          <FormControl>
            <FormLabel mb={0}>Description</FormLabel>
            <Input
              id={'description'}
              name={'description'}
              placeholder={'Enter a description for your template'}
              value={formValues.description}
              onChange={handleInputChange}
            />
            {errors.description ? (
              <Text color="red.500">{errors.description}</Text>
            ) : null}
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.chart_repo_url}>
            <FormLabel mb={0}>Repository URL</FormLabel>
            <Input
              id={'chart_repo_url'}
              name={'chart_repo_url'}
              placeholder={'Enter the chart repository'}
              value={formValues.chart_source.chart_repo_url}
              onChange={handleInputChange}
            />
            {errors.chart_repo_url ? (
              <Text color="red.500">{errors.chart_repo_url}</Text>
            ) : null}
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.chart_target_revision}>
            <FormLabel mb={0}>Target Revision</FormLabel>
            <Input
              id={'chart_target_revision'}
              name={'chart_target_revision'}
              placeholder={'Enter the chart target revision'}
              value={formValues.chart_source.chart_target_revision}
              onChange={handleInputChange}
            />
            {errors.chart_target_revision ? (
              <Text color="red.500">{errors.chart_target_revision}</Text>
            ) : null}
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.chart_path}>
            <FormLabel mb={0}>Chart Path</FormLabel>
            <Input
              id={'chart_path'}
              name={'chart_path'}
              placeholder={'Enter the chart path'}
              value={formValues.chart_source.chart_path}
              onChange={handleInputChange}
            />
            {errors.chart_path ? (
              <Text color="red.500">{errors.chart_path}</Text>
            ) : null}
          </FormControl>
          <FormControl>
            <FormLabel mb={0}>Tags</FormLabel>
            <CreatableSelect
              value={(formValues.tags || []).map((tag) => ({ label: tag, value: tag }))}
              onChange={(newValue) => {
                const updatedTags = newValue
                  ? newValue.map((option) => option.value)
                  : []
                setFormValues((prev) => ({
                  ...prev,
                  tags: updatedTags,
                }))
              }}
              // For some reason it doesn't recognize this data structure
              options={(tags.data?.tags || []).map((tag) => ({
                label: tag,
                value: tag,
              }))}
              isClearable
              isMulti
              menuPortalTarget={document.body}
              placeholder="Create or select tags"
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  marginTop: 0,
                  zIndex: 9999,
                }),
              }}
            />
          </FormControl>
        </VStack>
      </SectionCard>
      <SectionCard title={'Add template YAML'}>
        <FormControl isRequired isInvalid={!!errors.yaml}>
          {errors.yaml ? <Text color="red.500">{errors.yaml}</Text> : null}
          <TemplateEditor onChange={handleChangeYaml} />
        </FormControl>
      </SectionCard>
    </PageLayout>
  )
}
