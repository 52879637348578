import * as React from 'react'
import styles from './ClusterCodeMgr.module.css'
import { ReactEditor } from '../Editor/Editor'
import { EditorProps } from '@monaco-editor/react'

type Props = {
  configFiles: Array<Record<string, string>>
  customOptions: EditorProps['options']
  onChange?: EditorProps['onChange']
}

export function ClusterCodeMgr({
  configFiles,
  customOptions,
  onChange,
}: Props) {
  const configLen = configFiles.length

  return (
    <article>
      {configFiles.flat().map((config) => {
        const [key, value] = Object.entries(config)[0]
        return (
          <div
            className={styles.clusterConfigHeadFile}
            key={`${key}-${configLen}`}
          >
            <br />
            <p className={styles.clusterConfigHeadFileName}>{key}</p>

            <div className={styles.clusterConfigHeadData}>
              <ReactEditor
                onChange={onChange}
                options={{
                  ...customOptions,
                }}
                value={value}
                language={customOptions?.language}
              />
            </div>
          </div>
        )
      })}
    </article>
  )
}
