import * as React from 'react'
import styles from './ClusterConfigRevisions.module.css'
import { Table, TableRow } from '@/components/templates/Table/Table'
import { Status } from '@/@types'
import { Loader } from 'lucide-react'

import { formatDateTime } from '@/helpers/timeFormatter'
import { RevisionsContext } from '@/stores/clusterRevisions'
import { ClusterConfigContext } from '@/stores/clusterConfig'
import { createRoute, useNavigate } from '@tanstack/react-router'
import { clusterConfigsTabRoute } from '../ClusterConfigsTab/ClusterConfigsTab'

export const clusterConfigRevisionsRoute = createRoute({
  getParentRoute: () => clusterConfigsTabRoute,
  path: 'revisions',
  component: () => <ClusterConfigRevisions />,
})

function ClusterConfigRevisions() {
  const { state: revisionState } = React.useContext(RevisionsContext)
  const { state: clusterConfigState } = React.useContext(ClusterConfigContext)
  const nav = useNavigate()
  const params = clusterConfigRevisionsRoute.useParams()

  const headers = [
    {
      keyName: 'revisionId',
      title: 'Revision ID',
    },
    {
      keyName: 'createdAt',
      title: 'Created',
    },
    {
      keyName: 'updatedAt',
      title: 'Last Update',
    },
    { keyName: 'status', title: 'status' },
    { keyName: 'createdBy', title: 'Author' },
  ]

  const rows: Array<TableRow> = React.useMemo(() => {
    const activeRevisionID =
      clusterConfigState.specificCluster.data?.revision_id
    const sortedRevisions = revisionState.list.data?.sort(
      (a, b) =>
        new Date(b.updated_at || b.created_at).getTime() -
        new Date(a.updated_at || a.created_at).getTime()
    )
    return (
      sortedRevisions?.map((data) => ({
        rowKey: data.revision_id,
        columns: {
          revisionId: {
            value: (
              <div className={styles.revisionId}>
                {data.revision_id}
                {data.revision_id === activeRevisionID && (
                  <p className={styles.revisionIdActive}>Active</p>
                )}
              </div>
            ),
          },
          createdAt: {
            value: formatDateTime(data.created_at),
          },
          updatedAt: {
            value: formatDateTime(data.updated_at || data.created_at),
          },
          status: { value: data.approval_state.state },
          createdBy: { value: data.created_by },
        },
      })) ?? []
    )
  }, [revisionState.list, clusterConfigState])

  function handleClick(event: React.MouseEvent<HTMLElement>, row: TableRow) {
    nav({
      to: '/clusters/$orgId/$clusterId/files/$revisionId',
      params: {
        orgId: params.orgId,
        clusterId: params.clusterId,
        revisionId: row.rowKey,
      },
    })
  }

  return (
    <article className={styles.revisions}>
      {revisionState.list.status === Status.Success && (
        <Table headers={headers} rows={rows} onClick={handleClick} />
      )}
      {revisionState.list.status === Status.Success &&
        !revisionState.list.data?.length && (
          <p className={styles.revisionsEmpty}>
            There are no revisions for this cluster
          </p>
        )}
      {revisionState.list.status === Status.Loading && <Loader />}
    </article>
  )
}
