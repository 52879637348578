// biome-ignore lint/style/useImportType: <explanation>
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Card,
  CardBody,
  CardHeader,
  CardProps,
  HStack,
  Heading,
  Text,
} from '@chakra-ui/react'
// biome-ignore lint/style/useImportType: <explanation>
import React, { PropsWithChildren, ReactNode } from 'react'

type SectionCardProps = {
  /**
   * Title of the card element
   */
  title?: ReactNode
  /**
   * Description that will be displayed below the title
   */
  description?: ReactNode
  /**
   * Append below the CardBody useful for full width tables
   */
  append?: ReactNode
  /**
   * Add actions to the card header on the right hand side, useful for button actions
   */
  actions?: ReactNode
  /**
   * Alert description that will be displayed at the top of the card
   */
  alertDescription?: ReactNode
  /**
   * Alert status that will be used to color the alert
   * @default 'info'
   */
  alertStatus?: 'info' | 'warning' | 'success' | 'error'
} & CardProps

const alertColors: Record<
  NonNullable<SectionCardProps['alertStatus']>,
  string
> = {
  error: 'red.500',
  info: 'blue.500',
  success: 'green.500',
  warning: 'yellow.500',
}

export function SectionCard({
  title,
  description,
  children,
  append,
  actions,
  alertStatus = 'info',
  alertDescription,
  ...cardProps
}: PropsWithChildren<SectionCardProps>) {
  return (
    <Card
      w="100%"
      variant="outline"
      borderColor={alertDescription ? alertColors[alertStatus] : 'inherit'}
      overflow="hidden"
      {...cardProps}
    >
      {alertDescription ? (
        <Alert
          status={alertStatus}
          variant="subtle"
          flexDirection="row"
          alignItems="start"
          justifyContent="start"
          textAlign="left"
          w="100%"
          px={5}
        >
          <AlertIcon />
          <AlertDescription fontSize="sm">{alertDescription}</AlertDescription>
        </Alert>
      ) : null}

      <CardHeader mb={0} pb={0} pt={alertDescription ? 4 : 5}>
        <HStack w="100%" justify="space-between" alignItems="start">
          <Box>
            {title ? (
              <Heading
                as="h2"
                variant="h2"
                m={0}
                p={0}
                display="flex"
                flexDir="row"
                gap={1}
                alignItems="center"
              >
                {title}
              </Heading>
            ) : null}

            {description ? (
              <Text m={0} mt={0.5}>
                {description}
              </Text>
            ) : null}
          </Box>

          {actions ? (
            <HStack gap={2} pt={1}>
              {actions}
            </HStack>
          ) : null}
        </HStack>
      </CardHeader>

      {children ? <CardBody>{children}</CardBody> : null}

      {append ? <Box mt={3}>{append}</Box> : null}
    </Card>
  )
}
