import { formatDistanceToNow } from 'date-fns'

export function formatDateTime(date: string) {
  try {
    return new Intl.DateTimeFormat('en-US', {
      timeStyle: 'long',
      dateStyle: 'medium',
      timeZone: 'UTC',
    }).format(new Date(date))
  } catch (e) {
    // if a date is not provided or its format is invalid, display the date as is
    return date
  }
}

export function timeAgo(date: string) {
  return formatDistanceToNow(new Date(date), { addSuffix: true })
}
