import * as React from 'react'
import { HivesProvider } from './hives'
import { SpecificHiveProvider } from './specificHive'
import { OrganizationProvider } from './organization'
import { IdentitySupportProvider } from './identitySupport'
import { SelfConfigProvider } from './selfconfig'
import { ClusterConfigProvider } from './clusterConfig'
import { RevisionProvider } from './clusterRevisions'
import { TemplateProvider } from './templates'
import type { PropsWithChildren } from 'react'

const providers = [
  HivesProvider,
  SpecificHiveProvider,
  OrganizationProvider,
  IdentitySupportProvider,
  SelfConfigProvider,
  ClusterConfigProvider,
  RevisionProvider,
  TemplateProvider,
]

export function ContextProvider({ children }: PropsWithChildren) {
  return (
    <>
      {providers.reduceRight(
        (acc, Provider) => (
          <Provider>{acc}</Provider>
        ),
        children
      )}
    </>
  )
}
