import { Status } from '@/@types'
import { SelfConfigTab } from '@/components/organisms/SelfConfigTab/SelfConfigTab'
import { ClusterConfigContext } from '@/stores/clusterConfig'
import { Flex, Heading } from '@chakra-ui/react'
import { createRoute, Outlet } from '@tanstack/react-router'
import * as React from 'react'
import { clustersRootRoute } from '../../router/rootRoutes'

// TODO Oli: remove this hardcoded data
const tabProps = {
  revisionCount: 4,
  versionName: 'version 3a',
  lastUpdated: '1 day ago',
  author: 'Tolumide',
  displayMeta: [''],
}

export const clusterConfigsTabRoute = createRoute({
  getParentRoute: () => clustersRootRoute,
  path: '$orgId/$clusterId',
  component: () => <ClusterConfigsTab />,
})

function ClusterConfigsTab() {
  const { state: clusterState } = React.useContext(ClusterConfigContext)
  const hasFinishedLoading =
    clusterState.specificCluster.status === Status.Success
  const clusterName = clusterState.specificCluster.data?.system.global.name

  return (
    // TODO: use a spacing token rather than an actual value like 2rem
    <Flex as="article" direction="column" gap="2rem">
      <Heading as="h1" variant="h1" style={{ display: 'none' }}>
        Cluster Configs
      </Heading>
      {hasFinishedLoading ? (
        <Heading variant="h2">{clusterName}</Heading>
      ) : null}
      <SelfConfigTab {...tabProps} />

      <Outlet />
    </Flex>
  )
}
