import { DataTag } from '@/components/DataTag/DataTag'
import { useGetTemplates } from '@/generated/http-clients/v3ApiComponents'
import { timeAgo } from '@/helpers/timeFormatter'
import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { colors } from '@hivemq/ui-theme'
import { createRoute, Link } from '@tanstack/react-router'
import { Plus } from 'lucide-react'
import { useState } from 'react'
import { templatesRootRoute } from '../../../router/rootRoutes'
import * as React from 'react'

export const templateTableRoute = createRoute({
  getParentRoute: () => templatesRootRoute,
  path: '/',
  component: () => <TemplateTable />,
})

export function TemplateTable() {
  const [page, setPage] = useState(1)

  const templates = useGetTemplates({
    queryParams: { page: page, order: 'desc' },
  })

  function handleNextPage() {
    if (templates.data?.has_next_page) {
      setPage((prev) => prev + 1)
    }
  }

  function handlePreviousPage() {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }

  return (
    <Flex direction="column" gap="8">
      {/*
       FIXME @RobinAtherton - This is ported over from the old view, need to refactor as soon as possible
      */}
      <Flex justifyContent={'flex-end'} mb={'10'} gap={'4'}>
        <Link to="/templates/compare">
          <Button type="button" variant={'outline'} padding={'1 0'}>
            Compare Changes
          </Button>
        </Link>
        <Link to="/templates/create">
          <Button
            backgroundColor={colors.amber[300]}
            borderRadius={'8'}
            padding={'1 0'}
            gap={'1'}
            borderWidth={'1px'}
          >
            <Plus />
            New Template
          </Button>
        </Link>
      </Flex>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Name</Th>
            <Th>Tags</Th>
            <Th>Details</Th>
          </Tr>
        </Thead>
        <Tbody>
          {templates?.data?.templates?.map((template) => (
            <Tr key={template.template_id}>
              <Td>
                {/* Template ID */}
                <DataTag
                  colorScheme={'blue'}
                  value={template.template_id}
                  hasCopy={false}
                >
                  {template.template_id}
                </DataTag>
              </Td>
              <Td>
                {/* Template Name, creation date and author */}
                <Text>{template.name}</Text>
                <Text fontSize="sm" color="gray">
                  Created {timeAgo(template.created_at!)} by{' '}
                  {template.created_by}
                </Text>
              </Td>
              <Td>
                {/* Tags */}
                {template.tags?.map((tag) => (
                  <DataTag
                    mx={1}
                    my={1}
                    key={tag}
                    value={tag}
                    colorScheme={'orange'}
                    hasCopy={false}
                  >
                    {tag}
                  </DataTag>
                ))}
              </Td>
              <Td>
                <Link
                  to="/templates/$templateId"
                  params={{ templateId: template.template_id }}
                >
                  <Button
                    style={{ border: '1px solid' }}
                    p={0}
                    variant={'primary'}
                  >
                    Details
                  </Button>
                </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Box display="flex" justifyContent="space-between" mt={4}>
        <Button onClick={handlePreviousPage} isDisabled={page === 1}>
          Previous
        </Button>
        <Box>
          Page {templates.data?.current_page} of {templates.data?.total_pages}
        </Box>
        <Button
          onClick={handleNextPage}
          isDisabled={!templates.data?.has_next_page}
        >
          Next
        </Button>
      </Box>
    </Flex>
  )
}
