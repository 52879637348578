import {
  createRootRouteWithContext,
  createRoute,
  Outlet,
  redirect,
} from '@tanstack/react-router'
import React, { Suspense } from 'react'
import { App } from '../App'
import type { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'

type RootContext = {
  auth: IOktaContext
}

export const rootRoute = createRootRouteWithContext<RootContext>()({
  component: () => (
    <>
      <Outlet />
      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
    </>
  ),
})

export const applicationRootRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: '__VIRTUAL_ROOT__APPLICATION__',
  component: () => (
    <App>
      <Outlet />
    </App>
  ),
  beforeLoad: async ({ context: { auth }, location }) => {
    // In a real world scenario this would probably be a reusable function 'ensureAuthenticated'
    if (!(await auth.oktaAuth.isAuthenticated())) {
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
      })
    }
  },
})

/**
 * Acts as a virtual root for organization routes
 * This ensures a structure where all organization routes are nested under this root
 * But this can be updated later on and include a path which nest the <Outlet />
 */
export const organizationRootRoute = createRoute({
  getParentRoute: () => applicationRootRoute,
  id: '__VIRTUAL_ROOT__ORGANIZATION__',
  component: () => <Outlet />,
})

/**
 * Acts as a virtual root for cluster routes
 * This ensures a structure where all cluster routes are nested under this root
 * But this can be updated later on and include a path which nest the <Outlet />
 */
export const clustersRootRoute = createRoute({
  getParentRoute: () => applicationRootRoute,
  path: 'clusters',
  component: () => <Outlet />,
})

/**
 * Acts as a virtual root for template routes
 * This ensures a structure where all template routes are nested under this root
 * But this can be updated later on and include a path which nest the <Outlet />
 */
export const templatesRootRoute = createRoute({
  getParentRoute: () => applicationRootRoute,
  path: 'templates',
  component: () => <Outlet />,
})
