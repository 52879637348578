import React, { PropsWithChildren } from 'react'

import './assets/styles/variables.css'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RootErrorBoundary } from './components/organisms/RootErrorBoundary/RootErrorBoundary'
import { ContextProvider } from './stores'
import { Layout } from './components/templates/Layout/Layout'
import { ChakraProvider } from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/react'
import { defineStyleConfig } from '@chakra-ui/react'
import { colors, components, styles, fonts } from '@hivemq/ui-theme'

import '@fontsource/raleway/400.css'
import '@fontsource/raleway/500.css'
import '@fontsource/raleway/600.css'
import '@fontsource/raleway/700.css'
import '@fontsource/raleway/800.css'
import '@fontsource/roboto'
import '@fontsource/roboto-mono/400.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'

const Drawer = defineStyleConfig({
  variants: {
    permanent: {
      // parts: ['dialog, dialogContainer'],
      dialog: {
        pointerEvents: 'auto',
      },
      dialogContainer: {
        pointerEvents: 'none',
      },
    },
  },
})

const theme = extendTheme({
  colors,
  components: {
    Drawer,
    ...components,
    Table: {
      baseStyle: {
        th: {
          whiteSpace: 'nowrap',
        },
      },
    },
  },
  styles,
  fonts,
})

const client = new QueryClient()

export function App({ children }: PropsWithChildren) {
  return (
    <ChakraProvider theme={theme}>
      {/* <RootErrorBoundary> */}
      <QueryClientProvider client={client}>
        <ContextProvider>
          <Layout>{children}</Layout>
        </ContextProvider>

        <ReactQueryDevtools />
      </QueryClientProvider>
      {/* </RootErrorBoundary> */}
    </ChakraProvider>
  )
}
