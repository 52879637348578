import { RenderRevisions, RevisionDisplay } from '@/@types/clusterConfig'
import { Template } from '@/generated/http-clients/v3ApiSchemas'

export function separateYamlFromJson(
  data: RenderRevisions['rendered_template'] | Template
): RevisionDisplay | undefined {
  const keys = Object.keys(data)
  if (keys.includes('yaml')) {
    const updatedData = Object.entries(data).reduce((acc, [key, value]) => {
      if (key !== 'yaml') {
        acc[key] = value
      }
      return acc
    }, {} as Record<string, any>)

    const yamlData = data?.['yaml']?.replace(/\\n/g, '\n')
    return { data: updatedData, yaml: yamlData } as RevisionDisplay
  }
}
