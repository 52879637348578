import {
  DynamicComparison,
  Props,
} from '@/components/organisms/DynamicComparison/DynamicComparison'
import { RevisionsContext } from '@/stores/clusterRevisions'
import * as React from 'react'
import YAML from 'yaml'
import { clusterConfigsTabRoute } from '../ClusterConfigsTab/ClusterConfigsTab'
import { createRoute } from '@tanstack/react-router'

export const dynamicClusterCompareRoute = createRoute({
  getParentRoute: () => clusterConfigsTabRoute,
  path: 'compare',
  component: () => <DynamicClusterCompare />,
})

export function DynamicClusterCompare() {
  const { state: revisionState, getRevisionById } =
    React.useContext(RevisionsContext)

  const [state, setState] = React.useState<Props['state']>({
    base: '',
    compare: '',
  })

  const params = dynamicClusterCompareRoute.useParams()

  const options: Props['options'] =
    revisionState.list.data?.map((data) => ({
      name: data.revision_id,
      id: String(data.revision_id),
    })) || []

  function onChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { name, value } = event.target
    setState((state) => ({ ...state, [name]: value }))
  }

  React.useEffect(() => {
    if (state.base && state.compare && params.orgId && params.clusterId) {
      getRevisionById(params.orgId, params.clusterId, state.base)
      getRevisionById(params.orgId, params.clusterId, state.compare)
    }
  }, [state, params])

  const changes = React.useMemo(() => {
    if (state.base && state.compare) {
      return [
        {
          data: YAML.stringify(
            revisionState.detailed[state.base]?.display?.data ?? ''
          ),
          modified: YAML.stringify(
            revisionState.detailed[state.compare]?.display?.data ?? ''
          ),
          id: 0,
        },
        {
          data: revisionState.detailed[state.base]?.display?.yaml ?? '',
          modified: YAML.stringify(
            revisionState.detailed[state.compare]?.display?.yaml ?? ''
          ),
          id: 1,
        },
      ]
    }
  }, [
    revisionState.detailed[state.base],
    revisionState.detailed[state.compare],
  ])

  return (
    <>
      <DynamicComparison
        onChange={onChange}
        state={state}
        changes={changes}
        options={options}
      />
    </>
  )
}
