import * as React from 'react'
import {
  Box,
  Button,
  Table as ChakraTable,
  HStack,
  ListItem,
  OrderedList,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table'

type Column = {
  value: React.ReactNode
}

export type TableRow = {
  rowKey: string
  columns: Record<string, Column>
}

type Props = {
  headers: Array<{
    title: string
    /**
     * used for mapping each row's column to the appropriate header title
     */
    keyName: string
  }>
  /**
   * headers and rows must have equal length
   */
  rows: Array<TableRow>
  onClick?: (e: React.MouseEvent<HTMLElement>, args: TableRow) => void
  paginationData?: Pagination
  onPageChange?: (_page: number) => void
}

export type Pagination = {
  pageIndex: number
  pageSize: number
  pageCount: number
  isLoading?: boolean
  totalPages: number
}

export function Table({
  headers,
  rows,
  onClick,
  paginationData,
  onPageChange,
}: Props) {
  const columns = headers.map<ColumnDef<TableRow>>((header) => ({
    accessorKey: header.keyName,
    header: () => header.title,
    cell: (info) => {
      const row = info.row.original
      const columnValue = row.columns[header.keyName]?.value

      return Array.isArray(columnValue) ? (
        <OrderedList pl={'6'} styleType={'lower-alpha'}>
          {(columnValue as React.ReactNode[]).map((item, index) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
        </OrderedList>
      ) : (
        columnValue
      )
    },
  }))

  const data = rows.map((row) => ({
    ...row,
    ...Object.entries(row.columns).reduce(
      (acc, [key, column]) => ({
        ...acc,
        [key]: column.value,
      }),
      {}
    ),
  }))

  const [pagination, setPagination] = React.useState({
    pageIndex: paginationData?.pageIndex || 1,
    pageSize: paginationData?.pageSize || 1,
  })

  const table = useReactTable<TableRow>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: paginationData?.pageCount || 1,
    onPaginationChange: setPagination,
    state: { pagination },
  })

  React.useEffect(() => {
    onPageChange?.(pagination.pageIndex)
  }, [pagination])

  return (
    <Box>
      <ChakraTable layout="fixed">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id} bg={'neutrals.50'}>
              {headerGroup.headers.map((header) => (
                <Th
                  border={'1px solid'}
                  borderColor={'neutrals.200'}
                  key={header.id}
                  fontSize={'md'}
                  textTransform={'capitalize'}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr
              key={row.id}
              onClick={(e) =>
                onClick?.(
                  e,
                  rows.find((r) => r.rowKey === row.original.rowKey)!
                )
              }
              _hover={{
                bg: 'neutrals.50',
                cursor: 'pointer',
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  border={'1px solid'}
                  borderColor={'neutrals.200'}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>

        {rows?.length ? (
          <HStack mt="8" justify="space-between">
            <Button
              variant={'primary'}
              p={'1 4'}
              onClick={() =>
                setPagination((prev) => ({
                  ...prev,
                  pageIndex: prev.pageIndex - 1,
                }))
              }
              isDisabled={
                pagination.pageIndex == 1 || paginationData?.isLoading
              }
            >
              Previous
            </Button>
            <span>
              Page {pagination.pageIndex} of {paginationData?.totalPages || 1}
            </span>
            <Button
              variant={'primary'}
              p={'1 4'}
              onClick={() =>
                setPagination((prev) => ({
                  ...prev,
                  pageIndex: prev.pageIndex + 1,
                }))
              }
              isDisabled={
                pagination.pageIndex >= (paginationData?.totalPages || 0) ||
                paginationData?.isLoading ||
                false
              }
            >
              Next
            </Button>
          </HStack>
        ) : null}
      </ChakraTable>
    </Box>
  )
}
