import { Hive } from '@/@types/hives'
import { HTTPMethod, useFetchCall } from '@/helpers/useFetchCall'
import * as React from 'react'
import { Status } from '@/@types'
import { useFindHives } from '@/generated/http-clients/v2ApiComponents'

type State = {
  status: Status
  hives: Array<Hive>
  pagination: {
    total_pages: number
    total_records: number
    current_page: number
    has_next_page: boolean
  }
}

type HiveParams = {
  email?: string
  page?: number
}

type Hives = {
  getAllHives: (params?: HiveParams, signal?: AbortSignal) => void
  appState: State
}

const pagination = {
  total_pages: 0,
  total_records: 0,
  current_page: 0,
  has_next_page: false,
}

export const HivesContext = React.createContext<Hives>({} as Hives)

export function HivesProvider({ children }: React.PropsWithChildren) {
  const { fetchCall } = useFetchCall()

  React.useEffect(() => {
    getAllHives()
  }, [])

  const [appState, setAppState] = React.useState<State>({
    status: Status.Rest,
    hives: [],
    pagination,
  })

  async function getAllHives(params?: HiveParams, signal?: AbortSignal) {
    if (params?.page === appState.pagination.current_page) {
      return
    }
    try {
      setAppState((state) => ({
        ...state,
        status: Status.Loading,
        pagination,
      }))
      const result = (await fetchCall<Record<string, any>>({
        path: 'hives',
        method: HTTPMethod.GET,
        params,
        signal,
      }))!

      const paginationData = Object.entries(result)?.reduce(
        (acc, [key, value]) => {
          if (key !== 'hives') {
            acc[key] = value
          }
          return acc
        },
        {} as Record<string, string>
      ) as unknown as State['pagination']

      setAppState((state) => ({
        ...state,
        status: Status.Success,
        hives: result?.hives ?? [],
        pagination: {
          ...paginationData,
          pageIndex: result?.hives?.length ? 1 : 0,
          current_page: params?.page || state.pagination.current_page,
        },
      }))
    } catch (error) {
      setAppState((state) => ({ ...state, status: Status.Error }))
    }
  }

  const value = React.useMemo(() => ({ getAllHives, appState }), [appState])

  return <HivesContext.Provider value={value}>{children}</HivesContext.Provider>
}
