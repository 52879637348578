import * as React from 'react'
import styles from './Button.module.css'
import classNames from 'classnames'

type DefaultButtonAttributes = React.ButtonHTMLAttributes<HTMLButtonElement>

type RequiredButtonAttributes = Pick<Required<DefaultButtonAttributes>, 'type'>

type Props = DefaultButtonAttributes &
  RequiredButtonAttributes & {
    classes?: string
  }

export function Button({
  classes,
  children,
  ...props
}: React.PropsWithChildren<Props>) {
  return (
    <button className={classNames(classes, styles.button)} {...props}>
      {children}
    </button>
  )
}
