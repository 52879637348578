// import { isTransactionMeta } from '@okta/okta-auth-js'
import { createRoute, redirect } from '@tanstack/react-router'
import { z } from 'zod'
import { rootRoute } from '../rootRoutes'

import { LoginCallback } from '@okta/okta-react'
import * as React from 'react'

export const loginRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/login',
  validateSearch: z.object({
    redirect: z.string().catch('/'),
  }),
  loaderDeps: ({ search }) => ({ originalUri: search.redirect }),
  loader: async ({ context: { auth }, deps }) => {
    // That part is not needed for the login flow
    // Let's keep it just in case that we need to access it again
    // const transactionStorage = auth.oktaAuth.storageManager
    //   .getTransactionStorage()
    //   .getStorage()

    // if (isTransactionMeta(transactionStorage)) {
    //   return
    // }

    const isAuthenticated = await auth.oktaAuth.isAuthenticated()

    if (isAuthenticated) {
      throw redirect({ to: deps.originalUri, replace: true })
    }

    await auth.oktaAuth.signInWithRedirect({ originalUri: deps.originalUri })
  },
})

export const loginCallbackRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/login/callback',
  component: () => <LoginCallback loadingElement={<div>loading...</div>} />,
})
