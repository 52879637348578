import { Status } from '@/@types'
import type {
    NewTemplate,
  Template,
  TemplateCreationResponse,
  TemplateDetail,
  TemplatesResponse,
} from '@/generated/http-clients/v3ApiSchemas'
import {
  ApiVersion,
  ErrorMessage,
  HTTPMethod,
  useFetchCall,
} from '@/helpers/useFetchCall'
import { useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from '@tanstack/react-router'
import * as React from 'react'

type TemplateData = {
  state: {
    list: {
      status: Status
      data: TemplateDetail[] | null
    }
    specificTemplate: {
      status: Status
      data: Template | null
      modifiedData: Template | null
    }
    createStatus: Status
    update: Status
    detailed: {
      [templateId: string]: {
        status: Status
        data: Template | null
      }
    }
  }
  getTemplateById: (_templateId: string) => Promise<void>
  onChangeCode: (_value?: string) => void
  onChangeChart: (event: React.ChangeEvent<HTMLInputElement>) => void
  createNewTemplate: (payload: NewTemplate) => Promise<void>
  resetUpdateStatus: () => void
}

const defaultData = {
  list: { status: Status.Rest, data: null },
  specificTemplate: {
    status: Status.Rest,
    data: null,
    modifiedData: null,
  },
  detailed: {},
  createStatus: Status.Rest,
  update: Status.Rest,
}

export const TemplateContext = React.createContext<TemplateData>({
  state: defaultData,
} as TemplateData)

export function TemplateProvider({ children }: React.PropsWithChildren) {
  const { fetchCall } = useFetchCall()

  // todo: make this more robust
  const params = useParams({ strict: false })
  const nav = useNavigate()
  const toast = useToast()

  React.useEffect(() => {
    getAllTemplates()
  }, [])

  React.useEffect(() => {
    if (params.templateId) getTemplateById(params.templateId)
  }, [params.templateId])

  const [state, setState] = React.useState<TemplateData['state']>(defaultData)

  async function getAllTemplates() {
    try {
      setState((state) => ({
        ...state,
        list: { status: Status.Loading, data: null },
      }))

      const data = (await fetchCall<TemplatesResponse>({
        path: `clusters/templates`,
        method: HTTPMethod.GET,
        version: ApiVersion.V3,
      }))!

      setState((state) => ({
        ...state,
        list: { status: Status.Success, data: data.templates || null },
      }))
    } catch (error) {
      setState((state) => ({
        ...state,
        list: { status: Status.Error, data: null },
      }))
    }
  }

  async function getTemplateById(templateId: string) {
    try {
      setState((state) => ({
        ...state,
        specificTemplate: {
          status: Status.Loading,
          data: null,
          modifiedData: null,
        },
        detailed: {
          [templateId]: { status: Status.Loading, data: null },
        },
      }))
      const data = (await fetchCall<Template>({
        path: `clusters/templates/${templateId}`,
        method: HTTPMethod.GET,
        version: ApiVersion.V3,
      }))!

      setState((state) => ({
        ...state,
        specificTemplate: {
          status: Status.Success,
          data,
          modifiedData: data,
        },
        detailed: {
          ...state.detailed,
          [templateId]: { status: Status.Success, data },
        },
      }))
    } catch (error) {
      const status =
        (error as Error).message === ErrorMessage.NotFound
          ? Status.Success
          : Status.Error
      setState((state) => ({
        ...state,
        specificTemplate: {
          status,
          data: null,
          modifiedData: null,
        },
        detailed: {
          [templateId]: { status: Status.Error, data: null },
        },
      }))
    }
  }

  function onChangeCode(value?: string) {
    if (value && state.specificTemplate.modifiedData) {
      setState((state) => ({
        ...state,
        specificTemplate: {
          ...state.specificTemplate,
          modifiedData: {
            ...state.specificTemplate.modifiedData!,
            yaml: value,
          },
        },
      }))
    }
  }

  function onChangeChart(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target
    setState((state) => ({
      ...state,
      specificTemplate: {
        ...state.specificTemplate,
        modifiedData: {
          ...state.specificTemplate.modifiedData!,
          chart_source: {
            ...state.specificTemplate.modifiedData!.chart_source,
            [name]: value,
          },
        },
      },
    }))
  }

  async function createTemplateRevision(templateId: string, comment: string) {
    try {
      setState((state) => ({ ...state, update: Status.Loading }))
      await fetchCall({
        path: `clusters/templates/${templateId}`,
        method: HTTPMethod.PUT,
        version: ApiVersion.V3,
        payload: {
          version: '',
          chart_source: state.specificTemplate.modifiedData?.chart_source,
          yaml: state.specificTemplate.modifiedData?.yaml,
          description: comment,
        },
      })
      setState((state) => ({ ...state, update: Status.Success }))

      // todo: update to real path
      nav({
        to: `templates/${templateId}/revisions`,
      })
    } catch (error) {
      setState((state) => ({ ...state, update: Status.Error }))
    }
  }

  async function createNewTemplate(payload: NewTemplate) {
    try {
      setState((state) => ({ ...state, createStatus: Status.Loading }))
      const data = (await fetchCall<TemplateCreationResponse>({
        path: `clusters/templates`,
        method: HTTPMethod.POST,
        version: ApiVersion.V3,
        payload: {
          version: '',
          name: payload.name,
          description: payload.description,
          chart_source: payload.chart_source,
          yaml: payload.yaml,
        },
      }))!
      setState((state) => ({ ...state, createStatus: Status.Success }))

      setTimeout(() => {
        getAllTemplates()
        setState((state) => ({ ...state, createStatus: Status.Rest }))
        nav({
          to: `templates/${data.template_id}`,
        })
      }, 500)

      toast({
        status: 'success',
        title: 'Template created successfully.',
      })
    } catch (error) {
      setState((state) => ({ ...state, createStatus: Status.Error }))
      toast({
        status: 'error',
        title: `Failed to create template: ${(error as Error).message}.`,
      })
    }
  }

  function resetUpdateStatus() {
    setState((state) => ({ ...state, update: Status.Success }))
  }

  const value = React.useMemo(
    () => ({
      state,
      getTemplateById,
      onChangeCode,
      onChangeChart,
      createNewTemplate,
      resetUpdateStatus,
    }),
    [state.list, state.specificTemplate, state.update],
  )

  return (
    <TemplateContext.Provider value={value}>
      {children}
    </TemplateContext.Provider>
  )
}
