import {
  DynamicComparison,
  Props,
} from '@/components/organisms/DynamicComparison/DynamicComparison'
import * as React from 'react'

import YAML from 'yaml'
import { TemplateContext } from '../../stores/templates'
import { createRoute } from '@tanstack/react-router'
import { templatesRootRoute } from '../../router/rootRoutes'

export const dynamicTemplateCompareRoute = createRoute({
  getParentRoute: () => templatesRootRoute,
  path: 'compare',
  component: () => <DynamicTemplateCompare />,
})

function DynamicTemplateCompare() {
  const { state: templateState, getTemplateById } =
    React.useContext(TemplateContext)

  const [state, setState] = React.useState<Props['state']>({
    base: '',
    compare: '',
  })

  const options: Props['options'] = (
    templateState.list.data?.map((data) => ({
      name: data?.name || data.template_id,
      id: String(data.template_id),
    })) || []
  ).sort((a, b) => Number(b.id) - Number(a.id))

  function onChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { name, value } = event.target
    setState((state) => ({ ...state, [name]: value }))
  }

  React.useEffect(() => {
    if (state.base && state.compare) {
      getTemplateById(state.base)
      getTemplateById(state.compare)
    }
  }, [state])

  const changes = React.useMemo(() => {
    if (state.base && state.compare) {
      return [
        {
          data: YAML.stringify(
            templateState.detailed[state.base]?.data?.chart_source ?? ''
          ),
          modified: YAML.stringify(
            templateState.detailed[state.compare]?.data?.chart_source ?? ''
          ),
          id: 0,
        },
        {
          data: templateState.detailed[state.base]?.data?.yaml ?? '',
          modified: templateState.detailed[state.compare]?.data?.yaml ?? '',
          id: 1,
        },
      ]
    }
  }, [
    templateState.detailed[state.base] || '',
    templateState.detailed[state.compare] || '',
  ])

  return (
    <DynamicComparison
      onChange={onChange}
      state={state}
      changes={changes}
      options={options}
    />
  )
}
