import { Status } from '@/@types'
import { HTTPMethod, useFetchCall } from '@/helpers/useFetchCall'
import * as React from 'react'

type OrgData = {
  storeState: { id: string; status: Status; members: Array<OrgMember> }
  getOrgMembers: (_orgId: string, signal: AbortSignal) => void
  removeAdmin: (_orgId: string, userId: string) => void
}

export type OrgMember = {
  id: string
  email: string
  role: string
  status: string
}

type StoreState = {
  id: string
  status: Status
  members: Array<OrgMember>
  memberStatus: Status
}

export const OrganizationContext = React.createContext<OrgData>({} as OrgData)

export function OrganizationProvider({ children }: React.PropsWithChildren) {
  const { fetchCall } = useFetchCall()

  const [storeState, setStoreState] = React.useState<StoreState>({
    id: '',
    status: Status.Rest,
    members: [],
    memberStatus: Status.Rest,
  })

  async function getOrgMembers(orgId: string, signal?: AbortSignal) {
    try {
      setStoreState((s) => ({ ...s, status: Status.Loading }))
      const result = await fetchCall({
        path: `orgs/${orgId}/users`,
        method: HTTPMethod.GET,
        signal,
      })
      setStoreState((s) => ({ ...s, status: Status.Success, members: result }))
    } catch (error) {
      setStoreState((s) => ({ ...s, status: Status.Error }))
    }
  }

  const removeAdmin = React.useCallback(
    async (orgId: string, userId: string) => {
      try {
        setStoreState((s) => ({ ...s, memberStatus: Status.Loading }))
        await fetchCall({
          path: `orgs/${orgId}/users/${encodeURIComponent(userId)}`,
          method: HTTPMethod.DELETE,
        })
        setStoreState((s) => ({
          ...s,
          memberStatus: Status.Success,
          members: s.members.filter((m) => m.id !== userId),
        }))
      } catch (error) {
        setStoreState((s) => ({ ...s, memberStatus: Status.Error }))
      }
    },
    []
  )

  const value = React.useMemo(
    () => ({ storeState, getOrgMembers, removeAdmin }),
    [storeState]
  )

  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  )
}
