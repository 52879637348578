import { Status } from '.'
import {
  LatestClusterRevision,
  Revision,
  Template,
  TemplateYaml,
} from '@/generated/http-clients/v3ApiSchemas'

export enum HiveState {
  APPLIED = 'APPLIED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  SUBMITTED = 'SUBMITTED',
}

export type ClusterConfig = {
  customer?: Record<string, any>
  hive_state: { state: string }
  revision_id: string
  system: Record<string, any>
}

// TODO Oli: remove and replace by generated types
export type ClusterConfigState = {
  clusters: {
    data: Array<LatestClusterRevision> | null
    status: Status
  }
  specificCluster: {
    data: ClusterConfig | null
    modifiedData: ClusterConfig | null

    status: Status
  }
  update: Status
  createStatus: Status
}

export type RenderRevisions = {
  rendered_template: {
    rollout: {
      revisionId: string
    }
    yaml: string
    chart_source: Record<string, any>
  }
}

export type ClusterRevisionChanges = {
  list: {
    status: Status
    data: Array<Revision> | null
  }
  update: {
    status: Status
  }
  detailed: Record<
    string,
    {
      status: Status
      data: Template | null
      display: RevisionDisplay | null
    }
  >
}

export type RevisionDisplay = {
  data: Omit<Template, 'yaml'>
  yaml: TemplateYaml
  id?: string
}
