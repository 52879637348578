import { Skeleton, Tag, TagProps, useClipboard } from '@chakra-ui/react'
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { Icon, useToast } from '@chakra-ui/icons'
import { CheckIcon, CopyIcon } from 'lucide-react'

type DataTagProps = {
  value?: string
  externalLink?: string
  maxLength?: number
  customToastTitle?: string
  hasCopy?: boolean
} & TagProps

export function DataTag({
  /**
   * The value that the tag represents. The visual value and this value that is used in the potential copy action are independent.
   */
  value,
  /**
   * Link to route to external resoruces from this tag. Only affects the clickable text portion of the tag so the copy button doesn't get overrriden.
   *
   */
  externalLink,
  /**
   * Max length that can optionally pass to shorten the visual value of the tag.
   */
  maxLength,
  /**
   * By default shows "{value} copied" in the toast. This message replaces the title.
   */
  customToastTitle,
  /**
   * By default shows a copy button, pass false to remove it.
   */
  hasCopy = true,
  children,
  ...tag
}: PropsWithChildren<DataTagProps>) {
  // @ts-ignore will always have copy if it is not configured away
  const { hasCopied, onCopy } = useClipboard(value)
  const toast = useToast()

  const [loading, setLoading] = useState(
    value === undefined || value === null || value === ''
  ) // Initial loading state
  const isEmpty = useMemo(
    () => value === undefined || value === null || value === '',
    [value]
  )

  useEffect(() => {
    if (externalLink) {
      setLoading(false)
    }
    if (!loading) return // Skip timeout if not loading
    const timeout = setTimeout(() => setLoading(false), 1500) // Skeleton for 1.5 seconds if needed
    return () => clearTimeout(timeout)
  }, [loading])

  function handleCopy() {
    onCopy()
    toast({
      status: 'success',
      title: customToastTitle || `Copied ${value} successfully.`,
    })
  }

  function handleExternalRoute() {
    if (externalLink) {
      window.open(externalLink, '_blank', 'noopener,noreferrer')
    }
  }

  const displayedValue = useMemo(() => {
    if (!value) return
    return maxLength && value.length > maxLength
      ? `${value?.slice(0, maxLength)}...`
      : value
  }, [value, maxLength])

  const isNotExternalLink = isEmpty && !externalLink

  return (
    <Skeleton isLoaded={!loading}>
      <Tag
        {...tag}
        _hover={{ cursor: 'pointer' }}
        colorScheme={!loading && isNotExternalLink ? 'red' : tag.colorScheme}
      >
        {loading ? (
          <span>Loading...</span>
        ) : isEmpty && isNotExternalLink ? (
          <span>N / A</span>
        ) : (
          <span onClick={handleExternalRoute}>
            {maxLength ? displayedValue : children}
          </span>
        )}

        {hasCopy && !loading && !isEmpty ? (
          <Icon
            ml={2}
            as={hasCopied ? CheckIcon : CopyIcon}
            onClick={handleCopy}
            color={hasCopied ? 'success.500' : 'inherit'}
          />
        ) : null}
      </Tag>
    </Skeleton>
  )
}
