import { DataTag } from '@/components/DataTag/DataTag'
import {
  getAuth0UserLink,
  getStripeCustomerLink,
} from '@/features/Organisations/components/ExternalLinks'
import { useFindHives } from '@/generated/http-clients/v2ApiComponents'
import { organizationRootRoute } from '@/router/rootRoutes'
import {
  Box,
  Button,
  Input,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { createRoute, useNavigate } from '@tanstack/react-router'
import { useState } from 'react'

import React from 'react'
import { organizationDetailsRoute } from '../components/OrganizationDetails'

export const organizationRoute = createRoute({
  getParentRoute: () => organizationRootRoute,
  path: '/',
  component: OrganizationTable,
})

function OrganizationTable() {
  const [page, setPage] = useState(1) // Current page
  const [filter, setFilter] = useState('') // Filter text
  //  TODO @RobinAtherton - Need to test if this pagination works out of the box
  const hives = useFindHives({ queryParams: { page: page } })

  function handleNextPage() {
    if (hives.data?.has_next_page) {
      setPage((prev) => prev + 1)
    }
  }

  function handlePreviousPage() {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }

  const navigate = useNavigate()

  // Filter the data based on the filter state
  const filteredHives = hives.data?.hives?.filter(
    (hive) =>
      hive.company_name?.toLowerCase().includes(filter.toLowerCase()) ||
      hive.org_id?.toLowerCase().includes(filter.toLowerCase()) ||
      hive.email?.some((email) =>
        email.email_address?.toLowerCase().includes(filter.toLowerCase())
      )
  )

  return (
    <Box>
      <Box mb={4}>
        <Input
          placeholder="Filter by organization name, org ID, or email"
          value={filter}
          onChange={(event) => setFilter(event.target.value)}
        />
      </Box>

      {filteredHives?.length === 0 ? (
        <Text>No hives found for the organization.</Text>
      ) : (
        <>
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>Organization</Th>
                <Th>Email(s)</Th>
                <Th>Account Information</Th>
                <Th>Stripe Information</Th>
                <Th>Details</Th>
              </Tr>
            </Thead>
            <Tbody>
              {hives.isLoading
                ? Array.from({ length: 5 }).map((_, index) => (
                    <Tr key={index}>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <Td key={index}>
                          <Skeleton height="20px" />
                        </Td>
                      ))}
                    </Tr>
                  ))
                : null}

              {filteredHives?.map((hive) => (
                <Tr key={hive.org_id}>
                  <Td>
                    <DataTag
                      whiteSpace={'nowrap'}
                      colorScheme={'blue'}
                      mb={1}
                      value={hive?.company_name}
                    >
                      {hive?.company_name || 'N/A'}
                    </DataTag>
                    <br />
                    <DataTag
                      whiteSpace={'nowrap'}
                      colorScheme={'blue'}
                      mt={1}
                      value={hive?.org_id}
                    >
                      {hive?.org_id}
                    </DataTag>
                  </Td>
                  <Td>
                    {hive.email?.map((email) => (
                      <>
                        <DataTag
                          mr={2}
                          mb={1}
                          mt={1}
                          whiteSpace={'nowrap'}
                          colorScheme={'blue'}
                          key={email.email_address}
                          value={email.email_address}
                        >
                          {email.email_address}
                        </DataTag>
                        <br />
                      </>
                    ))}
                  </Td>

                  <Td>
                    {hive.users_info?.map((info) => (
                      <>
                        <DataTag
                          mr={2}
                          mb={1}
                          mt={1}
                          colorScheme={'blue'}
                          externalLink={getAuth0UserLink(info.user_id)}
                          key={info.user_id}
                          value={info.user_id}
                        >
                          {info.user_id}
                        </DataTag>
                        <br />
                      </>
                    ))}
                  </Td>
                  <Td>
                    {hive.users_info?.map((info) => (
                      <>
                        {info.stripe_id ? (
                          <DataTag
                            mr={2}
                            mb={1}
                            mt={1}
                            colorScheme={'orange'}
                            externalLink={getStripeCustomerLink(info.stripe_id)}
                            key={info.stripe_id}
                            value={info.stripe_id}
                          >
                            {info.stripe_id}
                          </DataTag>
                        ) : null}
                      </>
                    ))}
                  </Td>
                  <Td>
                    {/*  FIXME @RobinAtherton - We need to figure out why buttons behave so weirdly in this project remove styling as soon as possible*/}
                    <Button
                      style={{ border: '1px solid' }}
                      p={0}
                      variant={'primary'}
                      onClick={() =>
                        navigate({
                          to: organizationDetailsRoute.to,
                          params: {
                            orgId: hive.org_id,
                          },
                        })
                      }
                    >
                      Details
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          <Box display="flex" justifyContent="space-between" mt={4}>
            <Button onClick={handlePreviousPage} isDisabled={page === 1}>
              Previous
            </Button>
            <Box>
              Page {hives.data?.current_page} of {hives.data?.total_pages}
            </Box>
            <Button
              onClick={handleNextPage}
              isDisabled={!hives.data?.has_next_page}
            >
              Next
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}
